import currencyjs from 'currency.js';

const checkCurrency = (actualCurrency, price) => {
  const currencyOptions = {
    PLN: {
      symbol: 'zł',
      pattern: '# !',
      separator: ' ',
      decimal: ',',
      precision: 0,
    },
    MXN: { symbol: '$', pattern: '! #', separator: ' ', decimal: '.' },
    BGN: { symbol: 'лв.', pattern: '# !', separator: '', decimal: ',' },
    USD: { symbol: '$', pattern: '! #', separator: ' ', decimal: ',' },
    EUR: { symbol: '€', pattern: '! #', separator: ' ', decimal: ',' },
    NIS: {
      symbol: 'NIS',
      pattern: '# !',
      separator: '',
      decimal: ',',
      precision: 0,
    },
    грн: {
      symbol: 'грн',
      pattern: '# !',
      separator: ' ',
      decimal: ',',
      precision: 0,
    },
    zł: {
      symbol: 'zł',
      pattern: '# !',
      separator: ' ',
      decimal: ',',
      precision: 0,
    },
    RON: {
      symbol: 'RON',
      pattern: '# !',
      separator: ' ',
      decimal: ',',
    },
  };

  return currencyjs(price, currencyOptions[actualCurrency]).format();
};

export default checkCurrency;
