import checkCurrency from './checkCurrency';
import getHeaders from "./headers";

(function ($) {

    $.fn.simpleWidget = function (simpleConfig) {

        var options = $.extend({
            showLoadingOnStart: false,
            productId: true,
            showCloseButtonInHeader: false,
            collectionUrl: '',
            showViewSwitch: false,
            linkToGallery: '',
            magnifierBtn: false,
            saveBtn: false,
            allowBackToList: false,
            fromVoxBox: false,
            includeLink: false,
            showDimensions: false,
            domain: null,
            furnitureSelectUrl: null,
            apiConfigurationUrl: null,
            saveConfigurationUrl: null,
            apiGetImageUrl: null,
            callbackSaveFurniture: null,
            callbackAvailabilityAndDelivery: null,
            callbackInstallment: null,
            showDeliveryTime: null,
            shopEnable: null,
            hasPrice: null,
            callbackSeeInShowroom: null,
            callbackAskQuestion: null,
            currency: null,
            alternativeBasket: false,
            lang: null,
            region: '',
            showOmnibus: false
        }, simpleConfig);

        var resource = null;
        var priceUpdated = false;
        var furniture = null;
        var currentViewState = null;

        function Accessories_Class(furnitureId, translator) {

            var self = this;


            var _elements = createDefaultElements(furnitureId, translator);


            var _presets = createPresets(furnitureId, _elements, translator);


            // function createPresets(furnitureId, elements, translator) {

            //     var presetManager = new Presets_Class(furnitureId, elements, translator);
            //     return presetManager.getPresets();
            // }

            function getElementForAccessoriesName(id, name, furnitureId, size, realWidth, translator, offset, preset) {

                function getThumb(name) {
                    return name + '.png';
                }

                function getUrl(name, furnitureId) {
                    return 'https://configurator-simple.vox.pl/assets/img/accessories/' + furnitureId + '_' + name + '.png';
                }

                function getDesc(name, translator) {

                    switch (name) {

                        case 'akc_memo':
                            return '';
                        case 'akc_probowka':
                            return '';
                        case 'akc_przybornik_duzy':
                            return '';
                        case 'akc_przybornik_sredni':
                            return '';
                        case 'akc_ramka':
                            return '';
                        case 'akc_tablet':
                            return '';
                        case 'akc_tablica':
                            return '';
                        case 'akc_telefon':
                            return '';
                        case 'akc_wyp25':
                            return '';
                        case 'akc_wyp40':
                            return '';
                        case 'akc_wyp15':
                            return '';
                    }

                    return '';
                }

                function getWidth(name, size) {

                    for (var i = 0; i < size.length; ++i) {

                        var s = size[i];
                        if (s.name === name) {
                            return s.c;
                        }
                    }

                    return 0;
                }

                function getArea(name, size) {

                    for (var i = 0; i < size.length; ++i) {

                        var s = size[i];
                        if (s.name === name) {
                            return {
                                a: s.a,
                                b: s.b
                            };
                        }
                    }

                    return null;
                }

                function createPopover(name, translator) {

                    var descriptionManager = new AccessoriesDescription_Class(translator);
                    var desc = descriptionManager.getDescription(name);
                    if (desc) {

                        return {
                            name: desc.header,
                            description: desc.desc,
                            url: desc.url
                        };
                    } else {

                        return null;
                    }
                }

                var thumb = getThumb(name);
                var desc = getDesc(name, translator);
                var url = getUrl(name, furnitureId);
                var width = getWidth(name, size);
                var area = getArea(name, size);
                var popover = '';
                // var popover = createPopover(name, translator);

                return getElement(id, name, thumb, desc, url, width, area, realWidth, offset, preset, popover);
            }

            /**
             * Tworzy obiekt reprezentujący wózek w aplikacji.
             *  @param id - identyfikator wózka.
             *  @param name - nazwa wózka.
             *  @param thumb - nazwa pliku miniaturki wyświetlanej w menu.
             *  @param desc - opis miniaturki wyświetlenej w menu.
             *  @param url - url do obrazka przedstawiającego wózek.
             *  @param width - przekątna obrazu.
             *  @param area - obiekt zawierający dwie wartości (a i b) opisujące szerokość i wysokość obrazka wózka.
             *  @param realWidth - szerokość wózka (fizyczna szerokość na listwie).
             *  @param offset - przesunięcie w lewo (o ile obrazek wózka nie zaczyna się przy tej właśnie krawędzi).
             *  @param preset - TRUE jeżeli element występuje tylko w ramach zestawu.
             *  @param popover - obiekt przechowujący info o dodatkowych informacjach wózka.
             */
            function getElement(id, name, thumb, desc, url, width, area, realWidth, offset, preset, popover) {

                var element = {
                    id: id,
                    name: name,
                    thumb: thumb,
                    desc: desc,
                    url: url,
                    width: width,
                    areaA: area,
                    realWidth: realWidth,
                    offset: offset ? offset : 0,
                    preset: (preset ? true : false),
                    popover: popover
                };

                element.bigUrl = '../assets/menu/thumbs/' + element.thumb.substring(0, element.thumb.lastIndexOf('.')) + '_big.png';

                return element;
            }

            function getSizeForBiurko140() {

                var size = [];

                size.push({
                    name: 'akc_wyp15',
                    a: 89,
                    b: 50,
                    c: 102
                });
                size.push({
                    name: 'akc_wyp25',
                    a: 145,
                    b: 80,
                    c: 166
                });
                size.push({
                    name: 'akc_wyp40',
                    a: 228,
                    b: 126,
                    c: 255
                });
                size.push({
                    name: 'akc_probowka',
                    a: 24,
                    b: 79,
                    c: 30
                });
                size.push({
                    name: 'akc_ramka',
                    a: 96,
                    b: 91,
                    c: 111
                });
                size.push({
                    name: 'akc_telefon',
                    a: 61,
                    b: 86,
                    c: 79
                });
                size.push({
                    name: 'akc_tablet',
                    a: 174,
                    b: 210,
                    c: 197
                });
                size.push({
                    name: 'akc_memo',
                    a: 70,
                    b: 102,
                    c: 79
                });
                size.push({
                    name: 'akc_przybornik_duzy',
                    a: 207,
                    b: 163,
                    c: 238
                });
                size.push({
                    name: 'akc_tablica',
                    a: 139,
                    b: 163,
                    c: 214
                });

                return size;
            }

            function getSizeForKomoda90() {

                var size = [];
                var scale = 1.24;

                size.push({
                    name: 'akc_wyp15',
                    a: 89 * scale,
                    b: 50 * scale,
                    c: 102 * scale
                });
                size.push({
                    name: 'akc_wyp25',
                    a: 145 * scale,
                    b: 80 * scale,
                    c: 166 * scale
                });
                size.push({
                    name: 'akc_wyp40',
                    a: 228 * scale,
                    b: 126 * scale,
                    c: 255 * scale
                });
                size.push({
                    name: 'akc_probowka',
                    a: 24 * scale,
                    b: 79 * scale,
                    c: 30 * scale
                });
                size.push({
                    name: 'akc_ramka',
                    a: 96 * scale,
                    b: 91 * scale,
                    c: 111 * scale
                });
                size.push({
                    name: 'akc_telefon',
                    a: 61 * scale,
                    b: 86 * scale,
                    c: 79 * scale
                });
                size.push({
                    name: 'akc_tablet',
                    a: 174 * scale,
                    b: 210 * scale,
                    c: 197 * scale
                });
                size.push({
                    name: 'akc_memo',
                    a: 70 * scale,
                    b: 102 * scale,
                    c: 79 * scale
                });
                size.push({
                    name: 'akc_tablica',
                    a: 139 * scale,
                    b: 163 * scale,
                    c: 214 * scale
                });
                size.push({
                    name: 'akc_przybornik_sredni',
                    a: 46 * scale,
                    b: 62 * scale,
                    c: 54 * scale
                });

                return size;
            }

            function getSizeForRTV180() {

                var size = [];
                var scale = 0.78;

                size.push({
                    name: 'akc_wyp15',
                    a: 89 * scale,
                    b: 50 * scale,
                    c: 102 * scale
                });
                size.push({
                    name: 'akc_wyp25',
                    a: 145 * scale,
                    b: 80 * scale,
                    c: 166 * scale
                });
                size.push({
                    name: 'akc_wyp40',
                    a: 228 * scale,
                    b: 126 * scale,
                    c: 255 * scale
                });
                size.push({
                    name: 'akc_probowka',
                    a: 24 * scale,
                    b: 79 * scale,
                    c: 30 * scale
                });
                size.push({
                    name: 'akc_ramka',
                    a: 96 * scale,
                    b: 91 * scale,
                    c: 111 * scale
                });
                size.push({
                    name: 'akc_telefon',
                    a: 61 * scale,
                    b: 86 * scale,
                    c: 79 * scale
                });
                size.push({
                    name: 'akc_tablet',
                    a: 174 * scale,
                    b: 210 * scale,
                    c: 197 * scale
                });
                size.push({
                    name: 'akc_memo',
                    a: 70 * scale,
                    b: 102 * scale,
                    c: 79 * scale
                });
                size.push({
                    name: 'akc_tablica',
                    a: 139 * scale,
                    b: 163 * scale,
                    c: 214 * scale
                });
                size.push({
                    name: 'akc_przybornik_sredni',
                    a: 46 * scale,
                    b: 62 * scale,
                    c: 54 * scale
                });
                size.push({
                    name: 'akc_przybornik_duzy',
                    a: 207 * scale,
                    b: 165 * scale,
                    c: 238 * scale
                });

                return size;
            }

            function getSizeForStolikNocny() {

                var size = [];
                var scale = 1.66;

                size.push({
                    name: 'akc_wyp15',
                    a: 89 * scale,
                    b: 50 * scale,
                    c: 102 * scale
                });
                size.push({
                    name: 'akc_wyp25',
                    a: 145 * scale,
                    b: 80 * scale,
                    c: 166 * scale
                });
                size.push({
                    name: 'akc_wyp40',
                    a: 236 * scale,
                    b: 127 * scale,
                    c: 256 * scale
                });
                size.push({
                    name: 'akc_probowka',
                    a: 24 * scale,
                    b: 79 * scale,
                    c: 30 * scale
                });
                size.push({
                    name: 'akc_ramka',
                    a: 96 * scale,
                    b: 91 * scale,
                    c: 111 * scale
                });
                size.push({
                    name: 'akc_telefon',
                    a: 61 * scale,
                    b: 86 * scale,
                    c: 79 * scale
                });
                size.push({
                    name: 'akc_tablet',
                    a: 174 * scale,
                    b: 210 * scale,
                    c: 197 * scale
                });
                size.push({
                    name: 'akc_memo',
                    a: 70 * scale,
                    b: 102 * scale,
                    c: 79 * scale
                });
                size.push({
                    name: 'akc_tablica',
                    a: 139 * scale,
                    b: 163 * scale,
                    c: 214 * scale
                });
                size.push({
                    name: 'akc_przybornik_sredni',
                    a: 46 * scale,
                    b: 62 * scale,
                    c: 54 * scale
                });
                size.push({
                    name: 'akc_przybornik_duzy',
                    a: 207 * scale,
                    b: 165 * scale,
                    c: 238 * scale
                });
                size.push({
                    name: 'akc_tablet_v2',
                    a: 174 * scale,
                    b: 210 * scale,
                    c: 197 * scale
                });

                return size;
            }

            /**
             * Akcesoria do indywidualnej konfiguracji to:
             *  - Uchwyt do telefonu
             *  - Ramka do zdjęć
             *  - Uchwyt do tabletu
             *  - Wazonik
             *  - Zaślepka 15 cm
             *  - Zaślepka 25 cm
             *  - Zaślepka 40 cm
             */
            function createDefaultElementsForST(size, furnitureId, translator) {

                var elements = [];

                elements.push(getElementForAccessoriesName(1, 'akc_wyp15', furnitureId, size, 15, translator)); // Zaślepka 15cm
                elements.push(getElementForAccessoriesName(2, 'akc_wyp25', furnitureId, size, 25, translator)); // Zaślepka 25cm
                elements.push(getElementForAccessoriesName(3, 'akc_wyp40', furnitureId, size, 40, translator)); // Zaślepka 40cm
                elements.push(getElementForAccessoriesName(6, 'akc_probowka', furnitureId, size, 3.5, translator)); // Wazonik
                elements.push(getElementForAccessoriesName(9, 'akc_ramka', furnitureId, size, 16.5, translator)); // Ramka do zdjęć
                elements.push(getElementForAccessoriesName(4, 'akc_telefon', furnitureId, size, 11, translator)); // Uchwyt do telefonu

                return elements
            }

            function createDefaultElements(furnitureId, translator) {

                function createDefaultElementsForST_biurko_140(furnitureId, translator) {

                    var size = getSizeForBiurko140();
                    var elements = createDefaultElementsForST(size, furnitureId, translator);

                    elements.push(getElementForAccessoriesName(10, 'akc_tablet', furnitureId, size, 31, translator)); // Uchwyt na tablet
                  
                    // TE ELEMENTY NIE WCHODZĄ BEZPOŚREDNIO W SKŁAD BIURKA:

                    elements.push(getElementForAccessoriesName(5, 'akc_memo', furnitureId, size, 12, translator, 0, true));
                    elements.push(getElementForAccessoriesName(7, 'akc_przybornik_duzy', furnitureId, size, 36, translator, 0, true));
                    elements.push(getElementForAccessoriesName(11, 'akc_tablica', furnitureId, size, 24, translator, 0, true));

                    return elements
                }

                function createDefaultElementsForST_komoda_90(furnitureId, translator) {

                    var size = getSizeForKomoda90();
                    var elements = createDefaultElementsForST(size, furnitureId, translator);

                    elements.push(getElementForAccessoriesName(10, 'akc_tablet', furnitureId, size, 31, translator)); // Uchwyt na tablet

                    // TE ELEMENTY NIE WCHODZĄ BEZPOŚREDNIO W SKŁAD KOMODY:

                    elements.push(getElementForAccessoriesName(5, 'akc_memo', furnitureId, size, 12, translator, 0, true));
                    elements.push(getElementForAccessoriesName(8, 'akc_przybornik_sredni', furnitureId, size, 7.5, translator, 0, true));

                    return elements
                }

                function createDefaultElementsForST_rtv_180(furnitureId, translator) {

                    var size = getSizeForRTV180();
                    var elements = createDefaultElementsForST(size, furnitureId, translator);

                    elements.push(getElementForAccessoriesName(10, 'akc_tablet', furnitureId, size, 31, translator)); // Uchwyt na tablet

                    return elements
                }

                function createDefaultElementsForST_stolik_nocny(furnitureId, translator) {

                    var size = getSizeForStolikNocny();
                    var elements = createDefaultElementsForST(size, furnitureId, translator);

                    elements.push(getElementForAccessoriesName(10, 'akc_tablet', furnitureId, size, 31, translator));
                    //elements.push(getElementForAccessoriesName(12, 'akc_tablet_v2', furnitureId, size, 31));  // Uchwyt na tablet (wersja alternatywna dla szafki nocnej)

                    // TE ELEMENTY NIE WCHODZĄ BEZPOŚREDNIO W SKŁAD STOLIKA NOCNEGO:

                    elements.push(getElementForAccessoriesName(8, 'akc_przybornik_sredni', furnitureId, size, 7.5, translator, 0, true));

                    return elements
                }

                function formatFurnitureId(furnitureId) {

                    var parts = furnitureId.split('_');
                    var formatedFurnitureId = 'ST_';

                    for (var i = 1; i < parts.length; ++i) {

                        var p = parts[i];
                        formatedFurnitureId += p.toLowerCase() + '_';
                    }

                    return formatedFurnitureId.substring(0, formatedFurnitureId.length - 1);
                }

                switch (formatFurnitureId(furnitureId)) {

                    case 'ST_biurko_140':
                        return createDefaultElementsForST_biurko_140(furnitureId, translator);
                    case 'ST_komoda_90_listwa':
                        return createDefaultElementsForST_komoda_90(furnitureId, translator);
                    case 'ST_rtv_180':
                    case 'ST_rtv_180_kw':
                        return createDefaultElementsForST_rtv_180(furnitureId, translator);
                    case 'ST_stolik_nocny_szuflady':
                    case 'ST_stolik_nocny_szuflady_kw':
                        return createDefaultElementsForST_stolik_nocny(furnitureId, translator);
                }

                return [];
            }

            function createPresets(furnitureId, elements, translator) {


                if (furnitureId == 'ST_biurko_140') {
                    return [
                        //biurko140 akcesoria
                        ['{"id":1,"name":"akc_wyp15","thumb":"akc_wyp15.png","desc":"","url":"../assets/img/accessories/STB140_akc_wyp15.png","width":102,"areaA":{"a":89,"b":50},"realWidth":15,"offset":0,"preset":false,"popover":{"name":"ZAŚLEPKA 15cm","description":"","url":"../assets/img/popover/akc_wyp15.png"},"bigUrl":"../assets/menu/thumbs/akc_wyp15_big.png"}', 'ST_B140_akcw15'],
                        ['{"id":2,"name":"akc_wyp25","thumb":"akc_wyp25.png","desc":"","url":"../assets/img/accessories/STB140_akc_wyp25.png","width":166,"areaA":{"a":145,"b":80},"realWidth":25,"offset":0,"preset":false,"popover":{"name":"ZAŚLEPKA 25cm","description":"","url":"../assets/img/popover/akc_wyp25.png"},"bigUrl":"../assets/menu/thumbs/akc_wyp25_big.png"}', 'ST_B140_akcw25'],
                        ['{"id":3,"name":"akc_wyp40","thumb":"akc_wyp40.png","desc":"","url":"../assets/img/accessories/STB140_akc_wyp40.png","width":255,"areaA":{"a":228,"b":126},"realWidth":40,"offset":0,"preset":false,"popover":{"name":"ZAŚLEPKA 40cm","description":"","url":"../assets/img/popover/akc_wyp40.png"},"bigUrl":"../assets/menu/thumbs/akc_wyp40_big.png"}', 'ST_B140_akcw40'],
                        ['{"id":6,"name":"akc_probowka","thumb":"akc_probowka.png","desc":"","url":"../assets/img/accessories/STB140_akc_probowka.png","width":30,"areaA":{"a":24,"b":79},"realWidth":3.5,"offset":0,"preset":false,"popover":{"name":"WAZON","description":"","url":"../assets/img/popover/akc_probowka.png"},"bigUrl":"../assets/menu/thumbs/akc_probowka_big.png"}', 'ST_B140_prob'],
                        ['{"id":9,"name":"akc_ramka","thumb":"akc_ramka.png","desc":"","url":"../assets/img/accessories/STB140_akc_ramka.png","width":111,"areaA":{"a":96,"b":91},"realWidth":16.5,"offset":0,"preset":false,"popover":{"name":"RAMKA NA ZDJĘCIE","description":"","url":"../assets/img/popover/akc_ramka.png"},"bigUrl":"../assets/menu/thumbs/akc_ramka_big.png"}', 'ST_B140_ramka'],
                        ['{"id":4,"name":"akc_telefon","thumb":"akc_telefon.png","desc":"","url":"../assets/img/accessories/STB140_akc_telefon.png","width":79,"areaA":{"a":61,"b":86},"realWidth":11,"offset":0,"preset":false,"popover":{"name":"UCHWYT NA TELEFON","description":"","url":"../assets/img/popover/akc_telefon.png"},"bigUrl":"../assets/menu/thumbs/akc_telefon_big.png"}', 'ST_B140_tel'],
                        ['{"id":10,"name":"akc_tablet","thumb":"akc_tablet.png","desc":"","url":"../assets/img/accessories/STB140_akc_tablet.png","width":197,"areaA":{"a":174,"b":210},"realWidth":31,"offset":0,"preset":false,"popover":{"name":"UCHWYT NA TABLET","description":"","url":"../assets/img/popover/akc_tablet.png"},"bigUrl":"../assets/menu/thumbs/akc_tablet_big.png"}', 'ST_B140_tab'],
                    ];
                }
                if (furnitureId == 'ST_komoda_90_listwa') {
                    return [
                        //komoda akcesoria
                        ['{"id":1,"name":"akc_wyp15","thumb":"akc_wyp15.png","desc":"","url":"../assets/img/accessories/STK90L_akc_wyp15.png","width":126.48,"areaA":{"a":110.36,"b":62},"realWidth":15,"offset":0,"preset":false,"popover":{"name":"ZAŚLEPKA 15cm","description":"","url":"../assets/img/popover/akc_wyp15.png"},"bigUrl":"../assets/menu/thumbs/akc_wyp15_big.png"}', 'ST_K_akcw15'],
                        ['{"id":2,"name":"akc_wyp25","thumb":"akc_wyp25.png","desc":"","url":"../assets/img/accessories/STK90L_akc_wyp25.png","width":205.84,"areaA":{"a":179.8,"b":99.2},"realWidth":25,"offset":0,"preset":false,"popover":{"name":"ZAŚLEPKA 25cm","description":"","url":"../assets/img/popover/akc_wyp25.png"},"bigUrl":"../assets/menu/thumbs/akc_wyp25_big.png"}', 'ST_K_akcw25'],
                        ['{"id":3,"name":"akc_wyp40","thumb":"akc_wyp40.png","desc":"","url":"../assets/img/accessories/STK90L_akc_wyp40.png","width":316.2,"areaA":{"a":282.71999999999997,"b":156.24},"realWidth":40,"offset":0,"preset":false,"popover":{"name":"ZAŚLEPKA 40cm","description":"","url":"../assets/img/popover/akc_wyp40.png"},"bigUrl":"../assets/menu/thumbs/akc_wyp40_big.png"}', 'ST_K_akcw40'],
                        ['{"id":6,"name":"akc_probowka","thumb":"akc_probowka.png","desc":"","url":"../assets/img/accessories/STK90L_akc_probowka.png","width":37.2,"areaA":{"a":29.759999999999998,"b":97.96},"realWidth":3.5,"offset":0,"preset":false,"popover":{"name":"WAZON","description":"","url":"../assets/img/popover/akc_probowka.png"},"bigUrl":"../assets/menu/thumbs/akc_probowka_big.png"}', 'ST_K_prob'],
                        ['{"id":9,"name":"akc_ramka","thumb":"akc_ramka.png","desc":"","url":"../assets/img/accessories/STK90L_akc_ramka.png","width":137.64,"areaA":{"a":119.03999999999999,"b":112.84},"realWidth":16.5,"offset":0,"preset":false,"popover":{"name":"RAMKA NA ZDJĘCIE","description":"","url":"../assets/img/popover/akc_ramka.png"},"bigUrl":"../assets/menu/thumbs/akc_ramka_big.png"}', 'ST_K_ramka'],
                        ['{"id":4,"name":"akc_telefon","thumb":"akc_telefon.png","desc":"","url":"../assets/img/accessories/STK90L_akc_telefon.png","width":97.96,"areaA":{"a":75.64,"b":106.64},"realWidth":11,"offset":0,"preset":false,"popover":{"name":"UCHWYT NA TELEFON","description":"","url":"../assets/img/popover/akc_telefon.png"},"bigUrl":"../assets/menu/thumbs/akc_telefon_big.png"}', 'ST_K_tel'],
                        ['{"id":10,"name":"akc_tablet","thumb":"akc_tablet.png","desc":"","url":"../assets/img/accessories/STK90L_akc_tablet.png","width":244.28,"areaA":{"a":215.76,"b":260.4},"realWidth":31,"offset":0,"preset":false,"popover":{"name":"UCHWYT NA TABLET","description":"","url":"../assets/img/popover/akc_tablet.png"},"bigUrl":"../assets/menu/thumbs/akc_tablet_big.png"}', 'ST_K_tab'],
                    ];
                }
                if (furnitureId == 'ST_stolik_nocny_szuflady' || furniture.code == 'ST_stolik_nocny_szuflady_kw') {
                    return [
                        // //stolik nocny akcesoria
                        ['{"id":1,"name":"akc_wyp15","thumb":"akc_wyp15.png","desc":"","url":"../assets/img/accessories/STSNS_akc_wyp15.png","width":169.32,"areaA":{"a":147.73999999999998,"b":83},"realWidth":15,"offset":0,"preset":false,"popover":{"name":"ZAŚLEPKA 15cm","description":"","url":"../assets/img/popover/akc_wyp15.png"},"bigUrl":"../assets/menu/thumbs/akc_wyp15_big.png"}', 'ST_SN_akcw15'],
                        ['{"id":2,"name":"akc_wyp25","thumb":"akc_wyp25.png","desc":"","url":"../assets/img/accessories/STSNS_akc_wyp25.png","width":275.56,"areaA":{"a":240.7,"b":132.79999999999998},"realWidth":25,"offset":0,"preset":false,"popover":{"name":"ZAŚLEPKA 25cm","description":"","url":"../assets/img/popover/akc_wyp25.png"},"bigUrl":"../assets/menu/thumbs/akc_wyp25_big.png"}', 'ST_SN_akcw25'],
                        ['{"id":3,"name":"akc_wyp40","thumb":"akc_wyp40.png","desc":"","url":"../assets/img/accessories/STSNS_akc_wyp40.png","width":424.96,"areaA":{"a":391.76,"b":210.82},"realWidth":40,"offset":0,"preset":false,"popover":{"name":"ZAŚLEPKA 40cm","description":"","url":"../assets/img/popover/akc_wyp40.png"},"bigUrl":"../assets/menu/thumbs/akc_wyp40_big.png"}', 'ST_SN_akcw40'],
                        ['{"id":6,"name":"akc_probowka","thumb":"akc_probowka.png","desc":"","url":"../assets/img/accessories/STSNS_akc_probowka.png","width":49.8,"areaA":{"a":39.839999999999996,"b":131.14},"realWidth":3.5,"offset":0,"preset":false,"popover":{"name":"WAZON","description":"","url":"../assets/img/popover/akc_probowka.png"},"bigUrl":"../assets/menu/thumbs/akc_probowka_big.png"}', 'ST_SN_prob'],
                        ['{"id":9,"name":"akc_ramka","thumb":"akc_ramka.png","desc":"","url":"../assets/img/accessories/STSNS_akc_ramka.png","width":184.26,"areaA":{"a":159.35999999999999,"b":151.06},"realWidth":16.5,"offset":0,"preset":false,"popover":{"name":"RAMKA NA ZDJĘCIE","description":"","url":"../assets/img/popover/akc_ramka.png"},"bigUrl":"../assets/menu/thumbs/akc_ramka_big.png"}', 'ST_SN_ramka'],
                        ['{"id":4,"name":"akc_telefon","thumb":"akc_telefon.png","desc":"","url":"../assets/img/accessories/STSNS_akc_telefon.png","width":131.14,"areaA":{"a":101.25999999999999,"b":142.76},"realWidth":11,"offset":0,"preset":false,"popover":{"name":"UCHWYT NA TELEFON","description":"","url":"../assets/img/popover/akc_telefon.png"},"bigUrl":"../assets/menu/thumbs/akc_telefon_big.png"}', 'ST_SN_tel'],
                        ['{"id":10,"name":"akc_tablet","thumb":"akc_tablet.png","desc":"","url":"../assets/img/accessories/STSNS_akc_tablet.png","width":327.02,"areaA":{"a":288.84,"b":348.59999999999997},"realWidth":31,"offset":0,"preset":false,"popover":{"name":"UCHWYT NA TABLET","description":"","url":"../assets/img/popover/akc_tablet.png"},"bigUrl":"../assets/menu/thumbs/akc_tablet_big.png"}', 'ST_SN_tab']
                    ];
                }
                if (furnitureId == 'ST_rtv_180' || furniture.code == 'ST_rtv_180_kw') {
                    return [
                        //rtv akcesoria
                        ['{"id":1,"name":"akc_wyp15","thumb":"akc_wyp15.png","desc":"","url":"../assets/img/accessories/STRTV180_akc_wyp15.png","width":79.56,"areaA":{"a":69.42,"b":39},"realWidth":15,"offset":0,"preset":false,"popover":{"name":"ZAŚLEPKA 15cm","description":"","url":"../assets/img/popover/akc_wyp15.png"},"bigUrl":"../assets/menu/thumbs/akc_wyp15_big.png"}', 'ST_rtv_akcw15'],
                        ['{"id":2,"name":"akc_wyp25","thumb":"akc_wyp25.png","desc":"","url":"../assets/img/accessories/STRTV180_akc_wyp25.png","width":129.48000000000002,"areaA":{"a":113.10000000000001,"b":62.400000000000006},"realWidth":25,"offset":0,"preset":false,"popover":{"name":"ZAŚLEPKA 25cm","description":"","url":"../assets/img/popover/akc_wyp25.png"},"bigUrl":"../assets/menu/thumbs/akc_wyp25_big.png"}', 'ST_rtv_akcw25'],
                        ['{"id":3,"name":"akc_wyp40","thumb":"akc_wyp40.png","desc":"","url":"../assets/img/accessories/STRTV180_akc_wyp40.png","width":198.9,"areaA":{"a":177.84,"b":98.28},"realWidth":40,"offset":0,"preset":false,"popover":{"name":"ZAŚLEPKA 40cm","description":"","url":"../assets/img/popover/akc_wyp40.png"},"bigUrl":"../assets/menu/thumbs/akc_wyp40_big.png"}', 'ST_rtv_akcw40'],
                        ['{"id":6,"name":"akc_probowka","thumb":"akc_probowka.png","desc":"","url":"../assets/img/accessories/STRTV180_akc_probowka.png","width":23.400000000000002,"areaA":{"a":18.72,"b":61.620000000000005},"realWidth":3.5,"offset":0,"preset":false,"popover":{"name":"WAZON","description":"","url":"../assets/img/popover/akc_probowka.png"},"bigUrl":"../assets/menu/thumbs/akc_probowka_big.png"}', 'ST_rtv_prob'],
                        ['{"id":9,"name":"akc_ramka","thumb":"akc_ramka.png","desc":"","url":"../assets/img/accessories/STRTV180_akc_ramka.png","width":86.58,"areaA":{"a":74.88,"b":70.98},"realWidth":16.5,"offset":0,"preset":false,"popover":{"name":"RAMKA NA ZDJĘCIE","description":"","url":"../assets/img/popover/akc_ramka.png"},"bigUrl":"../assets/menu/thumbs/akc_ramka_big.png"}', 'ST_rtv_ramka'],
                        ['{"id":4,"name":"akc_telefon","thumb":"akc_telefon.png","desc":"","url":"../assets/img/accessories/STRTV180_akc_telefon.png","width":61.620000000000005,"areaA":{"a":47.58,"b":67.08},"realWidth":11,"offset":0,"preset":false,"popover":{"name":"UCHWYT NA TELEFON","description":"","url":"../assets/img/popover/akc_telefon.png"},"bigUrl":"../assets/menu/thumbs/akc_telefon_big.png"}', 'ST_rtv_tel'],
                        ['{"id":10,"name":"akc_tablet","thumb":"akc_tablet.png","desc":"","url":"../assets/img/accessories/STRTV180_akc_tablet.png","width":153.66,"areaA":{"a":135.72,"b":163.8},"realWidth":31,"offset":0,"preset":false,"popover":{"name":"UCHWYT NA TABLET","description":"","url":"../assets/img/popover/akc_tablet.png"},"bigUrl":"../assets/menu/thumbs/akc_tablet_big.png"}', 'ST_rtv_tab']
                    ];
                }

                return null;
            }

            function createSpecialPopupHtmlContent(html) {

                return html.replace(/&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").replace(/"/g, "&quot;");
            }

            function createAccIdArray(elements) {

                var result = [];

                for (var i = 0; i < elements.length; ++i) {

                    var e = elements[i];
                    if (e != null) {
                        result.push(e.id);
                    }
                }

                return JSON.stringify(result);
            }

            function createPopover(preset, placement) {

                function createPopoverHeader(preset) {

                    var html = preset.popover.name;
                    return html;
                }

                function createPopoverContent(preset) {

                    var html = '<img class="text-center st-popup-image" src="' + preset.popover.url + '" /><p class="st-preset-popover">' + preset.popover.description + '</p>';
                    return html;
                }

                if (preset.popover) {

                    var popoverTitle = createSpecialPopupHtmlContent(createPopoverHeader(preset));
                    var popoverContent = createSpecialPopupHtmlContent(createPopoverContent(preset));

                    var popover = 'data-toggle="popover" data-placement="' + (placement ? placement : 'bottom') + '" data-trigger="hover" title="' + popoverTitle + '" data-content="' + popoverContent + '"';

                    return popover;
                } else {

                    return '';
                }
            }

            self.createPresetsHtml = function (createDescriptionPanel) {

                function createDescriptionContainer() {

                    var html = '<div class="st-preset-description-container">';

                    html += '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>';

                    html += '<div class="row">';

                    html += '<div class="col-md-12 col-sm-6 col-xs-12">';
                    html += '<img src="../assets/img/preset1.jpg" />';
                    html += '</div>';

                    html += '<div class="col-md-12 col-sm-6 col-xs-12">';

                    html +=
                            '<div class="st-preset-description-text">' +
                            '<p class="st-preset-description-text-main"></p>' +
                            '<p class="st-preset-description-text-more"></p>' +
                            '<p>' +
                            '<button id="st_accessories-preset-add" class="st-btn-red">' +
                            'Dodaj <span class="glyphicon glyphicon-plus" aria-hidden="true"></span>' +
                            '</button>' +
                            '</p>' +
                            '</div>';

                    html += '</div>';

                    html += '</div>';

                    return html;
                }

                var html = '';

                for (var i = 0; i < _presets.length; ++i) {

                    var p = _presets[i];

                    // Dodanie html'a.

                    var url = '../assets/menu/thumbs/' + p.thumb;
                    var popover = createPopover(p, "top");
                    var accId = createAccIdArray(p.elements);
                    var preId = p.id;

                    html +=
                            '<div class="dekor st-miniature-container" data-as-one="' + p.asOne + '" data-preset-id="' + p.id + '" data-name="' + p.name + '" data-acc-id="' + accId + '" ' + popover + '>' +
                            '<div class="dip-pw-color-sample img" style="background-image:' + "url('" + url + "')" + ';"></div>' +
                            '<div class="name">' + p.desc + '</div>' +
                            '</div>';
                }

                if (createDescriptionPanel) {
                    html += createDescriptionContainer();
                }

                return html;
            }

            self.createElementsHtml = function () {

                var html = '';

                for (var i = 0; i < _elements.length; ++i) {

                    var e = _elements[i];

                    // Jeżeli element występuje tylko w zestawie to nie jest wyświetlany na liście pojedynczych wózków.

                    if (!e.preset) {

                        var url = '../assets/menu/thumbs/' + e.thumb;
                        var popover = createPopover(e, "top");

                        html +=
                                '<div class="dekor st-miniature-container" data-name="' + e.name + '" data-acc-id="' + e.id + '" ' + popover + '>' +
                                '<div class="dip-pw-color-sample img" style="background-image:' + "url('" + url + "')" + ';"></div>' +
                                '<div class="name">' + e.desc + '</div>' +
                                '</div>';
                    }
                }

                return html;
            }

            self.getAccessoriesInfo = function (name) {

                for (var i = 0; i < _elements.length; ++i) {

                    var e = _elements[i];
                    if (e.name === name) {
                        return e;
                    }
                }

                return null;
            }

            self.getPresetInfo = function (id) {

                for (var i = 0; i < _presets.length; ++i) {

                    var e = _presets[i];
                    if (e.id === id) {
                        return e;
                    }
                }

                return null;
            }

            self.getPresetInfoByName = function (name) {

                for (var i = 0; i < _presets.length; ++i) {

                    var e = _presets[i];
                    if (e.name === name) {
                        return e;
                    }
                }

                return null;
            }

            self.getAccessoriesList = function () {

                return _elements;
            }
        }

        function AccessoriesArea_Class(areaA, areaB, start) {
            // [UWAGI]: Do obliczania pozycji wózków użyto stałych wartości dla największego widoku (rozmiar wózków jest jeden i wbity na sztywno).

            var self = this;

            var _areaA = areaA;
            var _areaB = areaB;
            var _start = start;

            var _debug = false;

            /**
             * Modyfikuje położenie wózka uwzględniając "perspektywę".
             */
            function takeIntoAccountThePerspective(x, y, scale, idx, realWidth, furnitureId) {

                function getMagicXValueForIdx(idx) {
                    return idx * 5;
                }

                function getMagicYValueForIdx(idx) {
                    return idx * 6;
                }

                function createOffsetForRealWidth(width, furnitureId) {

                    var offX = 752907.2 + (0.03085066 - 752907.2) / (1 + Math.pow(width / 3281548, 1.176677));
                    var offY = 752907.2 + (0.03085066 - 752907.2) / (1 + Math.pow(width / 3281548, 1.176677));

                    if (furnitureId && (furnitureId === 'ST_stolik_nocny_szuflady' || furnitureId === 'ST_stolik_nocny_szuflady_kw')) {
                        offX *= 2;
                        offY *= 2;
                    }
                    
                    if (furnitureId && (furnitureId === 'ST_biurko_140' || furnitureId === "ST_komoda_90_listwa")) {
                        offX *= 0.5;
                        offY *= 0.5;
                    }

                    return {
                        x: offX,
                        y: offY
                    };
                }

                var newX = x;
                var newY = y;

                var magicOffset = createOffsetForRealWidth(realWidth, furnitureId);

                newY -= (magicOffset.y + (idx === 0 ? 0 : 0)) * scale;

                if (_debug) {
                    console.log('skala: ' + scale + ' x: ' + x + '->' + newX + ' y: ' + y + '->' + newY);
                }

                return {
                    x: newX,
                    y: newY
                };
            }

            /**
             * Postkalkulacja miejsce na nowy wózek (nowy, czyli ten, który został przed chwilą dodany - znajduje się już w kolekcji accessories).
             *  @accessories - kolekcje wszystkich wózków.
             *  @screenWidth - aktualna szerokość widoku prezentującego podgląd.
             *  @screenHeight - aktualna wysokość widoku prezentującego podgląd.
             */
            self.getNewAccessoriesPosition = function (accessories, screenWidth, screenHeight, furnitureId) {

                return self.getPositionFor(accessories, screenWidth, screenHeight, accessories.length - 1, furnitureId);
            }

            /**
             * Przeliczenie rozmiaru wózka.
             *  @screenWidth - aktualna szerokość widoku prezentującego podgląd.
             *  @screenHeight - aktualna wysokość widoku prezentującego podgląd.
             *  @size - bazowy rozmiar wózka.
             */
            self.getNewAccessoriesSize = function (screenWidth, screenHeight, size) {

                var baseScreenWidth = 960;
                var baseScreenHeight = 570;

                var w = (size.a * screenWidth) / baseScreenWidth;
                var h = (size.b * screenWidth) / baseScreenWidth;

                return {
                    w: w,
                    h: h
                };
            }

            /**
             * Udostępnienie na zawnątrz obszaru zajmowanego przez szynę.
             */
            self.getAccessoriesArea = function () {
                return _areaB;
            }

            /**
             * Oblicza miejsce na nowy wózek (wg id).
             *  @accessories - kolekcje wszystkich wózków.
             *  @screenWidth - aktualna szerokość widoku prezentującego podgląd.
             *  @screenHeight - aktualna wysokość widoku prezentującego podgląd.
             *  @idx - indeks, do którego ma być zliczana szerokość wyłożonych wózków.
             */
            self.getPositionFor = function (accessories, screenWidth, screenHeight, idx, furnitureId) {

                function getYposForX(x, p1, p2, scale) {

                    var a = (p2.y - p1.y) / (p2.x - p1.x);
                    var b = p1.y - (a * p1.x);

                    return (a * x) + (b * scale);
                }

                function getMagicXValueForIdx(idx) {
                    return idx * 5;
                }

                function getMagicYValueForIdx(idx) {
                    return idx * 6;
                }

                var baseScreenWidth = _areaA.a;
                var baseScreenHeight = _areaA.b;

                var accXpos = 0;
                var realWidth = 0;

                for (var i = 0; i < idx; ++i) {

                    var w = accessories[i];

                    accXpos += w.areaA.a - w.offset;
                    realWidth += w.realWidth;
                }

                if (accessories.length > idx && idx != -1) {
                    accXpos -= accessories[idx].offset;
                }

                var scale = screenWidth / baseScreenWidth;

                var xPos = (((accXpos - getMagicXValueForIdx(idx)) * (screenWidth / 960)) + (_start.x * scale));
                var yPos = getYposForX(xPos, _start, {
                    x: _start.x + _areaB.a,
                    y: _start.y + _areaB.b
                }, scale);


                // Im dalej w las tym funkcja powinna lekko przechylać się w stronę osi X - nie może iść równo liniowo ze względu na perspetywę.

                return takeIntoAccountThePerspective(xPos, yPos, scale, idx, realWidth, furnitureId);
            }
        }

        var simpleTalkResources = function (lang) {
            var tableResource = {
                'trans_other_select_furniture': 'ok',
                'trans_other_table_legs_info': 'legs',
                'trans_other_table_legs_info_add': 'add',
                'trans_other_delete': 'delete',
                'trans_show_inspirations': 'inspiration',
                // 'trans_magnifier':'magnifier',
                'trans_magnifier': '',
                // 'trans_save_actual_configuration': 'configuration',
                'trans_save_actual_configuration': '',
                'trans_delete_last_acc': 'delete_last',
                'trans_otherClose': 'otherClose',
                'trans_menu_ok': 'menu_ok',
                // 'trans_menu_add_to_card':'add_to_card',
                'trans_menu_add_to_card': 'dodaj do koszyka',
                'trans_menu_unavailable': 'produkt niedostępny',
                // 'trans_menu_ready_presets':'read presets',
                'trans_menu_ready_presets': 'gotowe zestawy',
                // 'trans_menu_single_accessories':'single',
                'trans_menu_single_accessories': 'pojedyncze',
                'trans_black': 'czarny',
                'trans_white': 'biały',
                'trans_wood': 'dąb',
                'trans_gray': 'szary',
                // 'trans_legs-black':'legs-black',
                // 'trans_legs-wood':'legs-wood',
                // 'trans_legs-cone':'legs-cone',
                // 'trans_legs-trapeze':'legs-trapeze',
                // 'trans_legs-skids':'legs-skids',
                'trans_legs-black': 'czarna prosta',
                'trans_legs-gray': 'szara prosta',
                'trans_legs-white': 'biala prosta',
                'trans_legs-wood': 'dębowa prosta',
                'trans_legs-cone': 'dębowa owalna',
                'trans_legs-trapeze': 'czarna skośna',
                'trans_legs-skids': 'czarna metalowa',
                'trans_legs-none': 'brak',


                'img_stLargeFake': '../assets/img/fakes/stLargeFake.png',
                'img_stMediumFake': '../assets/img/fakes/stMediumFake.png',
                'img_stM3Fake': '../assets/img/fakes/stM3Fake.png',
                'img_stMediumFake': '../assets/img/fakes/stMediumFake.png',
                'img_stMediumFake': '../assets/img/fakes/stMediumFake.png',
                'img_stSmallFake': '../assets/img/fakes/stSmallFake.png',
                'img_stS2Fake': '../assets/img/fakes/stS2Fake.png',

                'img_XL1hover': '../assets/img/fakes/XL1_hover1.png',
                'img_XL2hover': '../assets/img/fakes/XL2_hover.png',
                'img_XL3hover': '../assets/img/fakes/XL3_hover.png',
                'img_XL2hover': '../assets/img/fakes/XL2_hover.png',
                'img_XL2hover': '../assets/img/fakes/XL2_hover.png',
                'img_Shover': '../assets/img/fakes/S_hover.png',
                'img_XShover': '../assets/img/fakes/XS_hover.png',


                'img_closedark': '../assets/img/closedark.png',
                'img_loader': '../assets/img/loader.gif',
                'img_icon_white': '../assets/menu/thumbs/bialy.jpg',
                'img_icon_white_m': '../assets/menu/thumbs/bialy.jpg',
                'img_icon_black': '../assets/menu/thumbs/czarny.jpg',
                'img_icon_black_sm': '../assets/menu/thumbs/czarny.jpg',
                'img_icon_wood': '../assets/menu/thumbs/wood_st.jpg',
                'img_icon_gray': '../assets/menu/thumbs/szary.jpg',
                'img_icon_legs-black': '../assets/menu/thumbs/legs-black.png',
                'img_icon_legs-wood': '../assets/menu/thumbs/legs-wood.png',
                'img_icon_legs-cone': '../assets/menu/thumbs/legs-cone.png',
                'img_icon_legs-white': '../assets/menu/thumbs/legs-white.png',
                'img_icon_legs-gray': '../assets/menu/thumbs/legs-gray.png',
                'img_icon_legs-none': '../assets/menu/thumbs/legs-none.png',
                'img_icon_legs-trapeze': '../assets/menu/thumbs/legs-trapeze.png',
                'img_icon_legs-skids': '../assets/menu/thumbs/legs-skids.png',
                'img_basket': '../assets/img/koszyk_white.svg',
                'img_icon_off_head': '../assets/img/empty.png',
            }

            return tableResource;
        }


        var simpleTalkCreator = function () {

            /**
             * Ustawia domyślny (pusty) stan podglądu - bez żadnych załadowanych obrazków (same fejki).
             */
            function SetDefaultPreviewState() {

                $('.mainImage.st-responsive-xl1').attr('src', resource['img_stLargeFake']);
                $('.mainImage.st-responsive-xl2').attr('src', resource['img_stMediumFake']);
                $('.mainImage.st-responsive-xl3').attr('src', resource['img_stM3Fake']);
                $('.mainImage.st-responsive-l').attr('src', resource['img_stMediumFake']);
                $('.mainImage.st-responsive-m').attr('src', resource['img_stMediumFake']);
                $('.mainImage.st-responsive-s').attr('src', resource['img_stSmallFake']);
                $('.mainImage.st-responsive-xs').attr('src', resource['img_stS2Fake']);

                $('.mainImageLoading.st-responsive-xl1').attr('src', resource['img_stLargeFake']);
                $('.mainImageLoading.st-responsive-xl2').attr('src', resource['img_stMediumFake']);
                $('.mainImageLoading.st-responsive-xl3').attr('src', resource['img_stM3Fake']);
                $('.mainImageLoading.st-responsive-l').attr('src', resource['img_stMediumFake']);
                $('.mainImageLoading.st-responsive-m').attr('src', resource['img_stMediumFake']);
                $('.mainImageLoading.st-responsive-s').attr('src', resource['img_stSmallFake']);
                $('.mainImageLoading.st-responsive-xs').attr('src', resource['img_stS2Fake']);
            }

            function createMenuStep(lp, type) {
                function createHeader(type) {
                    if (type == 'st_body') {
                        return resource['trans_menu_title_body'];
                    }
                    if (type == 'st_front') {
                        return resource['trans_menu_title_front'];
                    }
                    if (type == 'st_body_bed') {
                        return resource['trans_menu_title_chest'];
                    }
                    if (type == 'st_legs') {
                        return resource['trans_menu_title_legs'];
                    }
                    if (type == 'st_handles') {
                        return resource['trans_menu_title_handles'];
                    }
                    if (type == 'st_accessories') {
                        return resource['trans_menu_title_accessories'];
                    }
                    if (type == 'st_frame') {
                        return resource['trans_menu_title_frame'];
                    }
                    if (type == 'st_box' || type == 'st_front_bed') {
                        return resource['trans_menu_title_box'];
                    }
                    if (type == 'st_head') {
                        return resource['trans_menu_title_head'];
                    }
                    return resource['trans_menu_title_example'];
                }

                var stepId = type + '-group';
                var stepIdCollapsed = stepId + '-collapsed';
                var stepHeader = createHeader(type);
                var userMenuStepLp = lp ? lp : 1;
                var userMenuStepTextId = 'st-main-menu-' + userMenuStepLp + '-pos';

                if (type === 'st_accessories') {
                    var menu_ready_presets = resource["trans_menu_ready_presets"];
                    var menu_single_accessories = resource["trans_menu_single_accessories"];

                    var extendedHtml =
                            '<div class="panel panel-default">' +
                            '<div class="panel-heading" role="tab" id="' + stepId + '">' +
                            '<div class="panel-title">' +
                            '<a class="st-step-accordion-header-collapse collapsed" role="button" data-toggle="collapse" data-parent="#st-step-accordion" href="#' + stepIdCollapsed + '" aria-expanded="true" aria-controls="' + stepIdCollapsed + '">' +
                            '<div class="row">' +
                            '<div class="col-sm-12 st-step-accordion-header-label-container">' +
                            '<h2 id="' + userMenuStepTextId + '" class="st-step-accordion-header-label">' +
                            userMenuStepLp + '. ' + stepHeader +
                            '</h2>' +
                            '<i class="st-step-accordion-header-collapse-icon fas fa-chevron-down"></i>' +
                            '</div>' +
                            '</div>' +
                            '</a>' +
                            '</div>' +
                            '</div>' +
                            '<div id="' + stepIdCollapsed + '" class="panel-collapse collapse" role="tabpanel" aria-labelledby="' + stepId + '">' +
                            '<div class="panel-body">' +
                            '<p class="st_accessories-select-label">' +
                            '<span>-</span>' +
                            menu_ready_presets +
                            '</p>' +
                            '<div class="color-row select-kolor presetGroupMiniatures" data-type="' + type + '_full">' +

                            '</div>' +
                            '<p class="st_accessories-select-label">' +
                            '<span>-</span>' +
                            menu_single_accessories +
                            '</p>' +
                            '<div class="color-row select-kolor elementGroupMiniatures" data-type="' + type + '">' +

                            '</div>' +
                            '</div>' +
                            '</div>' +
                            '</div>';

                    return extendedHtml;
                } else {
                    var simpleHtml =
                            '<div class="panel panel-default">' +
                            '<div class="panel-heading' + (userMenuStepLp === 1 ? ' first' : '') + '" role="tab" id="' + stepId + '">' +
                            '<div class="panel-title">' +
                            '<a class="st-step-accordion-header-collapse collapsed" role="button" data-toggle="collapse" data-parent="#st-step-accordion" href="#' + stepIdCollapsed + '" aria-expanded="true" aria-controls="' + stepIdCollapsed + '">' +
                            '<div class="row">' +
                            '<div class="col-sm-12 st-step-accordion-header-label-container">' +
                            '<h2 id="' + userMenuStepTextId + '" class="st-step-accordion-header-label">' +
                            userMenuStepLp + '. ' + stepHeader +
                            '</h2>' +
                            '<i class="st-step-accordion-header-collapse-icon fas fa-chevron-down"></i>' +
                            '</div>' +
                            '</div>' +
                            '</a>' +
                            '</div>' +
                            '</div>' +
                            '<div id="' + stepIdCollapsed + '" class="panel-collapse collapse' + (userMenuStepLp === 1 ? ' in' : '') + '" role="tabpanel" aria-labelledby="' + stepId + '">' +
                            '<div class="panel-body">' +
                            '<div class="color-row select-kolor elementGroupMiniatures" data-type="' + type + '">' +

                            '</div>' +
                            '</div>' +
                            '</div>' +
                            '</div>';

                    return simpleHtml;
                }
            }

            function createDeliveryTimeElement(showDeliveryTime) {
                let html = '';
                if (showDeliveryTime) {
                    html += '<p class="st-delivery-container"></p>';
                }

                return html;
            }

            function createSimpleMenu() {
                var otherClose = resource["trans_otherClose"];

                var html =
                        '<div id="mainMenu" class="panel-group menu">' +
                        '<div class="st-main-menu-header">';

                if (options.allowBackToList) {
                    html += '<div id="backToList" class="st-configurator-close-container"><img src="' + resource["img_closedark"] + '" alt="' + otherClose + '"></div>';
                }

                if (options.fromVoxBox) {
                    html +=
                            '<div id="backToVoxBox" class="st-configurator-close-container">' +
                            '<img src="' + resource["img_closedark"] + '" alt="' + otherClose + '">' +
                            '</div>';
                }

                html +=
                        '<div class="st-main-menu-header-name">' +
                        '<h1 class="st-main-menu-header-name-bottom"></h1>' +
                        '</div>' +
                        '<div class="st-main-menu-dimensions"></div>';

                if (options.includeLink) {
                    html += createSimpleLink(simpleConfig.collectionUrl);
                }

                html +=
                        '</div>' +
                        '<div id="st-step-accordion" class="panel-group st-main-menu-sections" role="tablist" aria-multiselectable="true">' +

                        createMenuStep(1, 'st_body') +
                        createMenuStep(1, 'st_body_bed') +
                        createMenuStep(2, 'st_front') +
                        createMenuStep(2, 'st_front_bed') +
                        createMenuStep(3, 'st_box') +
                        createMenuStep(4, 'st_head') +
                        createMenuStep(5, 'st_legs') +
                        createMenuStep(6, 'st_handles') +
                        createMenuStep(7, 'st_accessories') +

                        '</div>' +

                        '<div class="st-main-menu-footer">' +
                        '<div class="st-additional-info-container">' +
                        createDeliveryTimeElement(options.showDeliveryTime) +
                        '<p class="st-installment-container"></p>' +
                        '</div>' +
                        '<div class="st-omnibus-price hidden">' +
                        '<p class="st-omnibus-price-txt">'+resource['trans_omnibus_txt']+'</p>' +
                        '<p class="st-omnibus-price-value"></p>' +
                        '</div>' +
                        '<div id="additionalButtonsContainer" class="panel panel-footer">' +
                        '<div class="row">';

                if (options.shopEnable)
                {
                    if (options.fromVoxBox) {
                        var menu_ok = resource["trans_menu_ok"];

                        html +=
                            '<div class="col-sm-12 st-btn-red-cell">' +
                            '<button id="st-open-voxbox-modal-btn" class="additionalButtons st-btn-red">' +
                            menu_ok +
                            '</button>' +
                            '</div>';
                    } else {
                        var menu_add_to_card = resource["trans_menu_add_to_card"];

                        html +=
                            '<div class="col-sm-4 st-btn-red-cell st-left">' +
                            '<div class="st-main-menu-price-value-container text-center">' +
                            '<div class="st-main-menu-price-value"></div>' +
                            '</div>' +
                            '</div>' +
                            '<div class="col-sm-8 st-btn-red-cell st-right">' +
                            '<button id="zatwierdz" class="additionalButtons st-btn-red" data-cy="AddToCart">' +
                            menu_add_to_card +
                            '<img src="' + resource['img_basket'] + '" alt="koszyk" class="st-koszyk-white">' +
                            '</button>' +
                            '</div>';
                    }
                } else {
                    html +=
                        `<span class="unavailable-info"><p><img src="https://static10.vox.pl/files/assets/img/icons/watch.svg" alt=""/>${resource["trans_unavailable_info"]}</p></span>`+
                        '<div class="no-shop">';
                    if (options.hasPrice)
                    {
                        html +=
                            '<div class="st-main-menu-price-value-container text-center w-100">' +
                            '<div class="st-main-menu-price-value"></div>' +
                            '</div>';
                    }
                    html +=
                        `<button id="seeInShowroom" class="additionalButtons st-btn-red">${resource["trans_see_in_showroom"]}</button>`+
                        `<button id="askQuestion" class="additionalButtons st-btn-grey">${resource["trans_ask_question"]}</button>`+
                        '</div>'
                }

                html +=
                    '</div>' +
                    '</div>' +
                    '</div>' +
                    '</div>';

                return html;
            }


            function createSimpleModal() {
                // var other_close = simpleTalkResources.getResource("other_select_furniture"); //other_close
                var other_close = resource['trans_other_select_furniture'];

                var html =
                        '<div id="st-configurator-modal" class="st-modal">' +
                        '<div class="st-modal-content">' +
                        '<span class="st-close">' +
                        '<img src="' + resource['img_closedark'] + '" alt="' + other_close + '">' +
                        '</span>' +
                        '<div class="st-modal-content-container">' +
                        '</div>' +
                        '</div>' +
                        '</div>';

                return html;
            }

            function createSimpleLink(url) {
                if (options.showDimensions) return '';

                var html =
                        '<p class="st-main-menu-header-collection">' +
                        '<a href="' + url + '">Simple</a>' +
                        '</p>';

                return html;
            }

            function createSimplePreview() {
                var html =
                        '<div id="mainImageContainer">' +
                        '<div class="magnification-content magnification-content--hidden"><img class="magnification-image magnification-image--hidden" src=""></div>' +
                        '<div id="main-image-container-loading-canvas">' +
                        '<img class="mainImageLoading st-responsive-xl1" src="' + resource['img_stLargeFake'] + '" data-responsive="XL1"/>' +
                        '<img class="mainImageLoading st-responsive-xl2" src="' + resource['img_stMediumFake'] + '" data-responsive="XL2"/>' +
                        '<img class="mainImageLoading st-responsive-xl3" src="' + resource['img_stM3Fake'] + '" data-responsive="XL3"/>' +
                        '<img class="mainImageLoading st-responsive-l" src="' + resource['img_stMediumFake'] + '" data-responsive="L"/>' +
                        '<img class="mainImageLoading st-responsive-m" src="' + resource['img_stMediumFake'] + '" data-responsive="L"/>' +
                        '<img class="mainImageLoading st-responsive-s" src="' + resource['img_stSmallFake'] + '" data-responsive="S"/>' +
                        '<img class="mainImageLoading st-responsive-xs" src="' + resource['img_stS2Fake'] + '" data-responsive="XS"/>' +
                        '</div>';

                if (options.showViewSwitch) {
                    var other_back = resource['trans_other_select_furniture']; //other_back;

                    html +=
                            '<div id="main-image-container-view-change-fake-with-back" class="btn-group" data-toggle="buttons">' +
                            '<label id="main-image-container-view-change-back" class="btn btn-primary st-configurator-close-container">' +
                            other_back +
                            '</label>' +
                            '</div>';
                }

                var other_table_legs_info = resource["trans_other_table_legs_info"];
                var other_table_legs_info_add = resource["trans_other_table_legs_info_add"];
                var other_delete = resource["trans_other_delete"];
                var show_inspirations = resource["trans_show_inspirations"];

                var magnifier = resource["trans_magnifier"];
                var save_actual_configuration = resource["trans_save_actual_configuration"];
                var delete_last_acc = resource["trans_delete_last_acc"];

                var inspirationsLink = "";
                var magnifierLink = "";
                var saveLink = "";

                if (options.linkToGallery) {
                    inspirationsLink = '<a id="st-go-to-inspiration" href="javascript:void(0);" class="st-btn-red openGalleryModal bound-openGalleryModal" data-modal-url="' + options.linkToGallery + '">' + show_inspirations + '</a>';
                }

                if (options.saveBtn) {
                    saveLink = '<div id="main-image-container-save-actual-configuration">' +
                            '<button type="button" class="btn btn-default">' +
                            '<span class="glyphicon glyphicon-download-alt" aria-hidden="true"></span>' +
                            '<span class="hiddenButtonText">' + save_actual_configuration + '</span>' +
                            '</button></div>';
                }
                if (options.magnifierBtn) {
                    magnifierLink = '<div id="main-image-container-magnifier">' +
                            '<button type="button" style="margin-bottom:40px;" class="btn btn-default">' +
                            '<span class="glyphicon glyphicon-search" aria-hidden="true"></span>' +
                            '<span class="hiddenButtonText">' + magnifier + '</span>' +
                            '</button></div>';
                }

                const getRemoveButton = function() {
                    if (!resource['img_icon_trash']) {
                        return '<button type="button" class="btn btn-default">' +
                                '<i class="fa fa-times" aria-hidden="true"></i>' +
                                '<span class="hiddenButtonText">' + delete_last_acc + '</span>' +
                                '</button>';
                    }

                    return '<button type="button" class="btn btn-default st-btn-trash">' +
                            '<span class="st-btn-trash-img">' +
                            '<img class="st-icon" src="' + resource['img_icon_trash'] + '" alt="" />' +
                            '</span>' +
                            '<span class="st-btn-trash-text">' + delete_last_acc + '</span>' +
                            '</button>';
                };

                html +=
                        '<div class="st-ghost-alert-container">' +
                        '<p class="st-ghost-alert-text text-center">' + other_table_legs_info + '</p>' +
                        '<p class="st-ghost-alert-text-additional text-center">' + other_table_legs_info_add + '</p>' +
                        '</div>' +
                        inspirationsLink +
                        // '<div id="main-image-container-accessories-remove">' +
                        // '<button type="button" class="btn btn-default">' + other_delete + '</button>' +
                        // '</div>' +
                        magnifierLink +
                        saveLink +
                        '<div id="main-image-container-delete-last-acc">' +
                        getRemoveButton() +
                        '</div>' +
                        '<img class="mainImage st-responsive-xl1" src="' + resource['img_stLargeFake'] + '" data-responsive="XL1"/>' +
                        '<img class="mainImage st-responsive-xl2" src="' + resource['img_stMediumFake'] + '" data-responsive="XL2"/>' +
                        '<img class="mainImage st-responsive-xl3" src="' + resource['img_stM3Fake'] + '" data-responsive="XL3"/>' +
                        '<img class="mainImage st-responsive-l" src="' + resource['img_stMediumFake'] + '" data-responsive="L"/>' +
                        '<img class="mainImage st-responsive-m" src="' + resource['img_stMediumFake'] + '" data-responsive="L"/>' +
                        '<img class="mainImage st-responsive-s" src="' + resource['img_stSmallFake'] + '" data-responsive="S"/>' +
                        '<img class="mainImage st-responsive-xs" src="' + resource['img_stS2Fake'] + '" data-responsive="XS"/>' +
                        '<img class="mainImageHover st-responsive-xl1" src="' + resource['img_XL1hover'] + '" data-responsive="XL1"/>' +
                        '<img class="mainImageHover st-responsive-xl2" src="' + resource['img_XL2hover'] + '" data-responsive="XL2"/>' +
                        '<img class="mainImageHover st-responsive-xl3" src="' + resource['img_XL3hover'] + '" data-responsive="XL3"/>' +
                        '<img class="mainImageHover st-responsive-l" src="' + resource['img_XL2hover'] + '" data-responsive="L"/>' +
                        '<img class="mainImageHover st-responsive-m" src="' + resource['img_XL2hover'] + '" data-responsive="L"/>' +
                        '<img class="mainImageHover st-responsive-s" src="' + resource['img_Shover'] + '" data-responsive="S"/>' +
                        '<img class="mainImageHover st-responsive-xs" src="' + resource['img_XShover'] + '" data-responsive="XS"/>' +
                        '<div id="st-special-loading-canvas">' +
                        '<img class="absolute-center" src="' + resource['img_loader'] + '" alt="VOX - Simple"/>' +
                        '</div>' +
                        '</div>';

                return html;
            }

            function createHtml() {
                var html =
                        '<div class="container simple-talk-container">' +

                        '<div id="fog"' + (options.showLoadingOnStart ? ' style="display:inline;"' : '') + '>' +
                        // '<img class="absolute-center" src="../assets/img/stLogoC.png" alt="VOX - Simple"/>' +
                        '<p> Wczytywanie .... </p>' +
                        '</div>';

                if (!options.productId) {
                    html += furnituresList;
                } else {
                    html +=
                            '<div class="st-configurator-work-place">' +

                            createSimpleModal() +

                            '<div class="row st-mobile-header">';

                    if (options.showCloseButtonInHeader) {
                        html +=
                                '<div class="st-configurator-close-container">' +
                                '<img src="../assets/img/closedark.png" alt="zamknij">' +
                                '</div>';
                    }

                    html +=
                            '<div class="st-main-menu-header col-12 col-md-5">' +
                            '<div class="st-main-menu-header-name">' +
                            '<h1 class="st-main-menu-header-name-bottom"></h1>' +
                            '<div class="st-main-menu-dimensions"></div>' +
                            '</div>' +
                            createSimpleLink(options.collectionUrl) +
                            '</div>' +

                            '</div>' +
                            '<div class="row">' +
                            '<div class="col-lg-7 col-xl-8 col-preview">' +
                            createSimplePreview() +
                            '</div>' +
                            '<div class="col-lg-5 col-xl-4 col-user-menu">' +
                            createSimpleMenu() +
                            '</div>' +
                            '</div>' +
                            '</div>';
                }

                html += '</div>';

                return html;
            }

            /* Koniec wszystkich funkcji odpowidająych za logikę */
            return createHtml();

        }

        var modelView = function (furniture) {
            function initView() {
               

                view = furniture.code + '/' + furniture.nozki + '/' + furniture.uchwyt + '/' + furniture.korpus + '/' + furniture.front + '/M-3.jpg';


                return view;
            }

            // $('#main-image-container-loading-canvas').html(initView());

            return initView();

        }

        /**
         * Odświeża aktualny widok konfiguratora na podstawie przekazanego stanu.
         *  @param view - obiekt widoku konfiguratora,
         *  @param state - ostatni odczytany z serwera stan,
         *  @param saveState - flaga określająca czy należy nadpisać stan, czy tylko metoda została wywołana jako odświeżenie aktualnego.
         */
        var refreshViewState = function (view, state, saveState) {

            function hasDetails() {
                if (furniture.akcesoria != false || furniture.akcesoria_full != false) {
                    return true;
                }
                return false;
            }

            /*
            Zwraca odpowiedni obrazek dla podanego progu responsywności.
                @level - próg zapisany jako łańcuch w postaci X, XL, itp.
                @images - kolekcja obiektów reprezentujących obrazki: { width, x, url: y }.
            */
            function getCorrectImageForResponsiveLevel(level, images) {

                function getCorrectImage(width, images) {

                    if (images == null) {
                        return null;
                    }

                    for (var i = 0; i < images.length; ++i) {

                        var img = images[i];
                        if (parseInt(img.width) === width) {
                            return img.url;
                        }
                    }

                    return null;
                }

                var correctImage = null;

                switch (level) {

                    case 'XL1':
                        correctImage = getCorrectImage(960, images);
                        break;
                    case 'XL2':
                        correctImage = getCorrectImage(734, images);
                        break;
                    case 'XL3':
                        correctImage = getCorrectImage(600, images);
                        break;
                    case 'L':
                        correctImage = getCorrectImage(734, images);
                        break;
                    case 'M':
                        correctImage = getCorrectImage(600, images);
                        break;
                    case 'S':
                        correctImage = getCorrectImage(367, images);
                        break;
                    case 'XS':
                        correctImage = getCorrectImage(300, images);
                        break;
                }

                if (correctImage == null) {
                    correctImage = images[2].url;
                }
                return correctImage;
            }

            function getCorrectFakeImageForResponsiveLevel(level) {

                var correctImage = '';

                switch (level) {

                    case 'XL1':
                        correctImage = resource['img_stLargeFake'];
                        break;
                    case 'XL2':
                        correctImage = resource['img_stMediumFake'];
                        break;
                    case 'XL3':
                        correctImage = resource['img_stM3Fake'];
                        break;
                    case 'L':
                        correctImage = resource['img_stMediumFake'];
                        break;
                    case 'M':
                        correctImage = resource['img_stM3Fake'];
                        break;
                    case 'S':
                        correctImage = resource['img_stSmallFake'];
                        break;
                    case 'XS':
                        correctImage = resource['img_stS2Fake'];
                        break;

                    default:
                        correctImage = resource['img_stLargeFake'];
                        break;
                }

                return correctImage;
            }

            function getCorrectHoverFakeImageForResponsiveLevel(level) {

                var correctImage = '';

                switch (level) {

                    case 'XL1':
                        correctImage = resource['img_XL1hover'];
                        break;
                    case 'XL2':
                        correctImage = resource['img_XL2hover'];
                        break;
                    case 'XL3':
                        correctImage = resource['img_XL3hover'];
                        break;
                    case 'L':
                        correctImage = resource['img_XL2hover'];
                        break;
                    case 'M':
                        correctImage = resource['img_XL2hover'];
                        break;
                    case 'S':
                        correctImage = resource['img_Shover'];
                        break;
                    case 'XS':
                        correctImage = resource['img_XShover'];
                        break;

                    default:
                        correctImage = resource['img_XL1hover'];
                        break;
                }

                return correctImage;
            }
            /**
             * Aktualizacja ceny produktu.
             *  @param price - nowa cena produktu.
             */
            function updatePrice(price) {
                var price = 0.0;
                var new_price = 0.0;
                var price_omnibus = 0;
                var set_price_omnibus = false;
                var currency = 'PLN';
                var st_no_head = false;
                st_no_head = furniture.front == 'off_head' ? true : false;
                $.each(element.element, function (key, value) {
                    if (value !== null) {
                        $.each(value.option, function (k, option) {
                            // setPrice(key, option);
                            if (key == 'st_body' || key == 'st_body_bed' || (st_no_head != true && key == 'st_box') ) {
                                if (option.material == furniture.korpus) {
                                    price += parseFloat(option.price);
                                    if (option.price_omnibus){
                                        price_omnibus += parseFloat(option.price_omnibus);
                                        set_price_omnibus = true;
                                    }
                                    if (option.price_promo){
                                        new_price += parseFloat(option.price_promo);
                                        if (option.price_omnibus == null){
                                            price_omnibus += parseFloat(option.price_promo);
                                        }
                                    } else {
                                        new_price += parseFloat(option.price);
                                        if (option.price_omnibus == null){
                                            price_omnibus += parseFloat(option.price);
                                        }
                                    }
                                    currency = option.currency;
                                }
                            }
                            
                            if (key == 'st_no_head') {
                                if (furniture.front == 'off_head' && ( option.material == furniture.korpus || ( option.material == 'off_head' && furniture.korpus == 'white' ) ) ) {
    
                                    price += parseFloat(option.price);
                                    if (option.price_omnibus){
                                        price_omnibus += parseFloat(option.price_omnibus);
                                        set_price_omnibus = true;
                                    }
                                    if (option.price_promo){
                                        new_price += parseFloat(option.price_promo);
                                        if (option.price_omnibus == null){
                                            price_omnibus += parseFloat(option.price_promo);
                                        }
                                    } else {
                                        new_price += parseFloat(option.price);
                                        if (option.price_omnibus == null){
                                            price_omnibus += parseFloat(option.price);
                                        }
                                    }
                                    currency = option.currency;
                                }
                            }

                            if ((key == 'st_front' || key == 'st_front_bed') || (st_no_head != true && key == 'st_head')) {
                                if (option.material == furniture.front) {
                                    price += parseFloat(option.price);
                                    if (option.price_omnibus){
                                        price_omnibus += parseFloat(option.price_omnibus);
                                        set_price_omnibus = true;
                                    }
                                    if (option.price_promo){
                                        new_price += parseFloat(option.price_promo);
                                        if (option.price_omnibus == null){
                                            price_omnibus += parseFloat(option.price_promo);
                                        }
                                    } else {
                                        new_price += parseFloat(option.price);
                                        if (option.price_omnibus == null){
                                            price_omnibus += parseFloat(option.price);
                                        }
                                    }
                                    currency = option.currency;
                                }
                            }

                            if (key == 'st_legs') {
                                if (option.material == furniture.nozki) {
                                    price += parseFloat(option.price);
                                    if (option.price_omnibus){
                                        price_omnibus += parseFloat(option.price_omnibus);
                                        set_price_omnibus = true;
                                    }
                                    if (option.price_promo){
                                        new_price += parseFloat(option.price_promo);
                                        if (option.price_omnibus == null){
                                            price_omnibus += parseFloat(option.price_promo);
                                        }
                                    } else {
                                        new_price += parseFloat(option.price);
                                        if (option.price_omnibus == null){
                                            price_omnibus += parseFloat(option.price);
                                        }
                                    }
                                    currency = option.currency;
                                }
                            }

                            if (key == 'st_handles') {
                                if (option.material == furniture.uchwyt) {
                                    price += parseFloat(option.price);
                                    if (option.price_omnibus){
                                        price_omnibus += parseFloat(option.price_omnibus);
                                        set_price_omnibus = true;
                                    }
                                    if (option.price_promo){
                                        new_price += parseFloat(option.price_promo);
                                        if (option.price_omnibus == null){
                                            price_omnibus += parseFloat(option.price_promo);
                                        }
                                    } else {
                                        new_price += parseFloat(option.price);
                                        if (option.price_omnibus == null){
                                            price_omnibus += parseFloat(option.price);
                                        }
                                    }
                                    currency = option.currency;
                                }
                            }

                            if (key == 'st_frame') {
                                if (option.material == furniture.rama) {
                                    price += parseFloat(option.price);
                                    if (option.price_omnibus){
                                        price_omnibus += parseFloat(option.price_omnibus);
                                        set_price_omnibus = true;
                                    }
                                    if (option.price_promo){
                                        new_price += parseFloat(option.price_promo);
                                        if (option.price_omnibus == null){
                                            price_omnibus += parseFloat(option.price_promo);
                                        }
                                    } else {
                                        new_price += parseFloat(option.price)
                                        if (option.price_omnibus == null){
                                            price_omnibus += parseFloat(option.price);
                                        }
                                    }
                                    currency = option.currency;
                                }
                            }

                            if (key == 'st_accessories_full') {
                                if (furniture.akcesoria_full.length > 0 && option.material == furniture.akcesoria_full[0]) {
                                    price += parseFloat(option.price);
                                    if (option.price_omnibus){
                                        price_omnibus += parseFloat(option.price_omnibus);
                                        set_price_omnibus = true;
                                    }
                                    if (option.price_promo){
                                        new_price += parseFloat(option.price_promo);
                                        if (option.price_omnibus == null){
                                            price_omnibus += parseFloat(option.price_promo);
                                        }
                                    } else {
                                        new_price += parseFloat(option.price)
                                        if (option.price_omnibus == null){
                                            price_omnibus += parseFloat(option.price);
                                        }
                                    }
                                    currency = option.currency;
                                }
                            }

                        });

                        if (key == 'st_accessories') {
                            for (var i = furniture.akcesoria.length - 1; i >= 0; i--) {
                                $.each(value.option, function (k, option) {
                                    if (option.material == furniture.akcesoria[i]) {
                                        price += parseFloat(option.price);
                                        if (option.price_omnibus){
                                            price_omnibus += parseFloat(option.price_omnibus);
                                            set_price_omnibus = true;
                                        }
                                        if (option.price_promo){
                                            new_price += parseFloat(option.price_promo);
                                            if (option.price_omnibus == null){
                                                price_omnibus += parseFloat(option.price_promo);
                                            }
                                        } else {
                                            new_price += parseFloat(option.price);
                                            if (option.price_omnibus == null){
                                                price_omnibus += parseFloat(option.price);
                                            }
                                        }
                                        currency = option.currency;
                                    }
                                });
                            }
                        }
                    }
                });

                if(!currency) currency = options.currency;
                var value = price === 'N/A' ? 'N/A' : checkCurrency(currency, Number(price));
                var new_value = new_price === 'N/A' ? 'N/A' : checkCurrency(currency, Number(new_price));


                var html = false;
                $('.st-omnibus-price').addClass('hidden');    
                if (new_price < price){
                    html = '<span class="st-main-menu-price-promo" style="text-decoration: line-through">'+value+'</span></br><span>'+new_value+'</span>'

                    if( set_price_omnibus && options.showOmnibus ){
                        $('.st-omnibus-price-value').html(checkCurrency(currency, Number(price_omnibus)))
                        $('.st-omnibus-price').removeClass('hidden');    
                    }
                    else{
                        $('.st-omnibus-price').addClass('hidden');    
                    }
                }

                if (html){
                    $('.st-main-menu-price-value').html(html);
                } else {
                    $('.st-main-menu-price-value').text(value);
                }

                if (!priceUpdated) {
                    priceUpdated = true;
                } else {
                    $('.st-main-menu-price-from').remove();
                }

                furniture.main_price = price <= new_price ? price : new_price;

                if (options.callbackInstallment && typeof options.callbackInstallment === 'function') {
                    options.callbackInstallment(price <= new_price ? value : new_value);
                }
            }

            /*
            Obsługa odpowiedzi zawierającej błąd.
                @reponse - odpowiedź serwera.
            */
            function handleNegativeServerResponse(response) {

                var message = response && response.message ? response.message : 'Nieznany błąd';
                console.log('Brak poprawnej odpowiedzi z serwera: ' + message);
            }

            function setFurnitureSize(width, depth, height) {

                $('.st-main-menu-size-values .st-main-menu-size-values-width').text(width);
                $('.st-main-menu-size-values .st-main-menu-size-values-depth').text(depth);
                $('.st-main-menu-size-values .st-main-menu-size-values-height').text(height);
            }

            var SIZED_ENABLED = false;

            if (state && state.result === 'true') {

                var currentResponsive = $('.mainImage:visible').data('responsive');

                // Zaktualizowanie wszystkich widoków dla różnych szerokości ekranu.

                var imagePreview = $(".mainImage");
                for (var i = 0; i < imagePreview.length; ++i) {

                    var ip = $(imagePreview).get(i);
                    var responsive = $(ip).data('responsive');

                    var imagesCollection = view.isMainView() ? state.mainUrl : state.accessoriesUrl;

                    var aimAttr = currentResponsive == responsive ? 'src' : 'data-src';
                    $(ip).attr(aimAttr, getCorrectImageForResponsiveLevel(responsive, imagesCollection));

                    if (currentResponsive != responsive) {

                        var fakeSrc = getCorrectFakeImageForResponsiveLevel(responsive);
                        $(ip).attr('src', fakeSrc);

                        $(ip).unveil();
                    } else {

                        // Zaktualizowanie podkładki bez wywoływania metody triggerującej podmianę.

                        $(ip).attr('data-src', getCorrectImageForResponsiveLevel(responsive, imagesCollection));
                    }
                }

                // Odrysowanie zaślepki tylko w przypadku, w którym mebel posiada listwę.

                if (hasDetails()) {

                    // Pętla po wszytkich hoverach i aktualizacja danych.

                    var hoverImagesCollection = state.hoverUrl;
                    var imageHover = $('.mainImageHover');

                    for (var i = 0; i < imageHover.length; ++i) {

                        var ip = $(imageHover).get(i);
                        var responsive = $(ip).data('responsive');

                        var aimAttr = currentResponsive == responsive ? 'src' : 'data-src';
                        $(ip).attr(aimAttr, getCorrectImageForResponsiveLevel(responsive, hoverImagesCollection));

                        if (currentResponsive != responsive) {

                            var fakeSrc = getCorrectHoverFakeImageForResponsiveLevel(responsive);
                            $(ip).attr('src', fakeSrc);

                            $(ip).unveil();
                        } else {

                            // Zaktualizowanie podkładki bez wywoływania metody triggerującej podmianę.

                            $(ip).attr('data-src', getCorrectImageForResponsiveLevel(responsive, hoverImagesCollection));
                        }
                    }
                }

                // Zaktualizowanie zaślepki.

                if (!saveState) {

                    var imagePreview = $("#main-image-container-loading-canvas .mainImageLoading");
                    for (var i = 0; i < imagePreview.length; ++i) {

                        var ip = $(imagePreview).get(i);
                        var responsive = $(ip).data('responsive');

                        var imagesCollection = view.isMainView() ? state.mainUrl : state.accessoriesUrl;

                        var aimAttr = currentResponsive == responsive ? 'src' : 'data-src';
                        $(ip).attr(aimAttr, getCorrectImageForResponsiveLevel(responsive, imagesCollection));

                        if (currentResponsive != responsive) {
                            $(ip).unveil();
                        }
                    }
                }

                // Aktualizacja wymiarów.

                if (SIZED_ENABLED) {

                    var furnitureSize = furniture.GetCurrentFurnitureSize();
                    setFurnitureSize(furnitureSize[0], furnitureSize[1], furnitureSize[2]);
                }

                // Zapamiętanie aktualnego stanu widoku.

                if (saveState) {

                    view.setViewState(state);

                    // Aktualizacja ceny.

                    // var furniturePrice = GetFurniturePriceBasedOnCurrentState();
                    updatePrice(furniture);
                }
            } else {
                handleNegativeServerResponse(state);
            }
        }

        var viewState = function () {

            var MAIN_VIEW = 1;
            var DETAILS_VIEW = 2;

            var self = this;

            var mode = MAIN_VIEW;
            var viewState = null;

            function setView(s) {
                viewState = s;
            }

            function setMode(m) {
                mode = m;
            }

            self.setMainView = function () {
                setMode(MAIN_VIEW);
            }
            self.setDetailsView = function () {
                setMode(DETAILS_VIEW);
            }
            self.setViewState = function (s) {
                setView(s);
            }

            self.isMainView = function () {
                return mode === MAIN_VIEW;
            }
            self.isDetailsView = function () {
                return mode === DETAILS_VIEW;
            }

            self.getState = function () {
                return viewState;
            }
        }


       var createMenu = function () {

            furniture = {
                code: null,
                name: null,
                id: null,
                front: null,
                nozki: null,
                korpus: null,
                uchwyt: null,
                wozki: [],
                akcesoria: [],
                akcesoria_full: [],
                rama: null,
                isDetails: false,
                isBed: false,
                countHeadOrNotHead: 0,
                bigAreaCalculator: null,
                largeAreaCalculator: null,
                mediumAreaCalculator: null,
                smallAreaCalculator: null,
                tinyAreaCalculator: null,
                allAccessoriesAreFromPreset: '',
                busWidth: 0,
                dimension: null,
            }

            function haveToRemoveAllAccessories() {
                return furniture.allAccessoriesAreFromPreset != '';
            }

            function redrawAllAccessories() {

                function addElementToStage(element, lp, furniture) {
                    
                    var responsiveArray = [{
                        name: "xl1",
                        width: 960,
                        height: 570
                    },
                        {
                            name: "xl2",
                            width: 734,
                            height: 570
                        },
                        {
                            name: "xl3",
                            width: 600,
                            height: 570
                        },
                        {
                            name: "l",
                            width: 734,
                            height: 570
                        },
                        {
                            name: "m",
                            width: 600,
                            height: 570
                        },
                        {
                            name: "s",
                            width: 367,
                            height: 285
                        },
                        {
                            name: "xs",
                            width: 300,
                            height: 233
                        }
                    ];

                    var respons = $('#mainImageContainer img:visible:not(.st-icon)').data('responsive');

                    for (var i = 0; i < responsiveArray.length; ++i) {

                        var r = responsiveArray[i];
                        var name = r.name;

                        // Generowanie warstwy w zależności od rozmiarów kontenera.

                        var imageContainer = $('#mainImageContainer');

                        var width = $(imageContainer).width();
                        var height = $(imageContainer).height();

                        var newPos = getExsistingAccessoriesPosition(width, height, lp - 1);
                        var newSize = getNewAccessoriesSize(width, height, element.areaA);

                        var bottom = newPos.y;
                        var left = newPos.x;

                        var w = newSize.w;
                        var h = newSize.h;

                        var style = 'style="position:absolute; bottom: ' + bottom + 'px; left:' + left + 'px; width: ' + w + 'px; height: ' + h + 'px;"';
                        var imgHtml =
                                '<img class="st_accessories-img st-responsive-' + name + '"' +
                                ' src="' + element.url + '" ' + style +
                                ' data-accessories-id="' + element.id + '" data-accessories-lp="' + lp + '" data-accessories-repons="' + name.toUpperCase() + '">';

                        $('#mainImageContainer').append(imgHtml);

                        $('#mainImageContainer').find('.st_accessories-img:last').hide();
                    }
                }

                $('#mainImageContainer .st_accessories-img').remove();

                for (var i = 0; i < furniture.wozki.length; ++i) {

                    var w = furniture.wozki[i];
                    addElementToStage(w, i + 1, this);
                }

                // var accessories = menu.simpleCreator.getUnavailableAccessories();
                // for(var a in accessories)
                // {
                //     $(".st_accessories-img[src*='" + a + "']").each(function(){
                //         $(this).css("opacity","0.6");
                //     });
                // }

            }

            function removeAllAccesories() {

                furniture.wozki = [];
                furniture.akcesoria = [];
                furniture.akcesoria_full = [];
            }

            function forceChangeTheOthersVisibility() {

                var allCollapse = $(this).parents('.st-main-menu-sections').find('.panel-collapse');

                for (var i = 0; i < allCollapse.length; ++i) {
                    var c = allCollapse[i];
                    if ($(c).attr('id') !== $(this).attr('id')) {
                        if ($(c).parent().find('.st-step-accordion-header-collapse').attr('aria-expanded') === 'true') {
                            $(c).collapse('hide');
                        } else
                            if ($(c).parent().find('.st-step-accordion-header-collapse').attr('aria-expanded') === 'false') {
                                $(c).parent().find('.panel-collapse').removeClass('show');
                            }
                    }
                }
            }

            function removeLastAccessories() {

                if (furniture.wozki.length > 0) {

                    var lastElement = furniture.wozki[furniture.wozki.length - 1];
                    furniture.wozki.splice(-1, 1);
                    return lastElement;
                }

                return null;
            }

            function getAllAccessories() {
                return furniture.wozki;
            }

            function hideTableAlert() {
                $('.st-ghost-alert-container').fadeOut('slow');
            }


            function ChangeViewToMain() {

                if (currentViewState.isDetailsView()) {
                    // disableMagnifier();
                    $('#st-special-loading-canvas').fadeIn('fast', function () {

                        currentViewState.setMainView();

                        $('#main-image-container-accessories-remove').hide();
                        $('#main-image-container-delete-last-acc').hide();
                        $('.st_accessories-img').hide();
                        $('.mainImageHover').hide();

                        var state = currentViewState.getState();
                        refreshViewState(currentViewState, state, false);

                        // Pokazanie komuniakatu z biurek.

                        // if (haveToShowTableAlert(LEGS, furniture.nozki, furniture)) {
                        //     showTableAlert();
                        // } else {
                        //     hideTableAlert();
                        // }

                        window.setTimeout(function () {
                            $('#st-special-loading-canvas').fadeOut('slow');
                        }, 500);
                    });
                }
            }

            /*
        Wyświetla aktualną warstwę akcesorii.
        */
            function showActualAccessoriesLayer() {

                var respons = $('#mainImageContainer img:visible:not(.st-icon)').data('responsive');

                var accessoriesImgs = $('.st_accessories-img');
                for (var i = 0; i < accessoriesImgs.length; ++i) {

                    var a = accessoriesImgs[i];
                    var resp = $(a).data('accessories-repons');

                    if (resp === respons) {
                        $(a).show();
                    }
                }

                // Pokazanie odpowiedniej zaślepki.

                var hoverImgs = $('.mainImageHover');
                for (var i = 0; i < hoverImgs.length; ++i) {

                    var h = hoverImgs[i];
                    var resp = $(h).data('responsive');

                    if (resp === respons) {
                        $(h).show(); /*$(h).unveil();*/
                    }
                }
            }

            function ChangeViewToDetails() {

                if (currentViewState.isMainView()) {

                    // disableMagnifier();
                    $('#st-special-loading-canvas').fadeIn('fast', function () {

                        currentViewState.setDetailsView();

                        var state = currentViewState.getState();
                        refreshViewState(currentViewState, state, false);

                        if (getAllAccessories().length) {

                            // Przerysowanie akcesoriów.

                            redrawAllAccessories();

                            // Przycisk do usuwania akcesorii widoczny jest tylko w trybie edycji.

                            var isInEditMode = $('#mainMenu:visible').length > 0;
                            if (isInEditMode) {
                                $('#main-image-container-accessories-remove').show();
                                $('#main-image-container-delete-last-acc').show();
                            }

                            showActualAccessoriesLayer();
                        }

                        hideTableAlert();

                        window.setTimeout(function () {
                            $('#st-special-loading-canvas').fadeOut('slow');
                        }, 1000);
                    });
                }
            }

            function ShowLoadingViewContent() {

                $('#main-image-container-loading-canvas').show();
            }

            var useServerMethod = true;

            function updateFurniture(furniture) {
                if (useServerMethod) {

                    function HideLoadingViewContent(view, state) {

                        $('#main-image-container-loading-canvas').fadeOut('fast', function () {
                            refreshViewState(view, state, false);
                        });
                    }

                    function handleImageSuccess(data, state) {

                        var response = JSON.parse(JSON.stringify(data));
                        refreshViewState(state, response, true);

                        // todo: cover powinien zniknąć teoretycznie dopiero jak wczytany obraz zostanie załadowany na stronę (load)...

                        window.setTimeout(function () {
                            HideLoadingViewContent(state, response);
                        }, 300);
                    }

                    function handleImageFail(data, state) {

                        /*
                    Zwraca odpowiedni obrazek dla podanego progu responsywności (z domyślnych zasobów).
                        @level - próg zapisany jako łańcuch w postaci X, XL, itp.
                    */
                        function getCorrectImageForResponsiveLevelLocal(level) {

                            return '../assets/img/stXMediumFake.png';
                        }

                        var imagePreview = $(".mainImage");
                        for (var i = 0; i < imagePreview.length; ++i) {

                            var ip = $(imagePreview).get(i);
                            var responsive = $(ip).data('responsive');

                            $(ip).attr("src", getCorrectImageForResponsiveLevelLocal(responsive));
                        }

                        HideLoadingViewContent(state, null);
                    }

                    ShowLoadingViewContent();
                    // furniture.code = furniture.code.replace(/_/g, '-')
                    var json = JSON.stringify(furniture);

                
                    // modelView(furniture);
                    currentViewState = new viewState();
                    $.ajaxSetup({
                        headers:getHeaders()
                    });
                    $.post(options.apiGetImageUrl, {
                        data: json
                    })
                            .done(function (data) {
                                handleImageSuccess(data, currentViewState);
                            })
                            .fail(function (data) {
                                handleImageFail(data, currentViewState);
                            });
                } else {

                    var imagePreview = $(".mainImage");
                    for (var i = 0; i < imagePreview.length; ++i) {

                        var ip = $(imagePreview).get(i);
                        var responsive = $(ip).data('responsive');

                        $(ip).attr("src", modelView(furniture));
                    }
                }

            }

            function ChangeElement(type, value) {
                function haveToShowTableAlert(type, value, furniture) {

                    if (furniture.code == 'ST_biurko_140' && value == 'legs-none') {
                        return true;
                    }
                    return false;
                }

                function showTableAlert(hide, duration) {
                    $('.st-ghost-alert-container').fadeIn('slow', function () {

                        if (hide) {

                            setTimeout(function () {
                                $('.st-ghost-alert-container').fadeOut('slow');
                            }, duration ? duration : 3000);
                        }
                    });
                }

                function hideTableAlert() {
                    $('.st-ghost-alert-container').fadeOut('slow');
                }


                switch (type) {
                    case 'st_front':
                    case 'st_head':
                    case 'st_front_bed':
                        furniture.front = value;
                        break;
                    case 'st_legs':
                        furniture.nozki = value;
                        break;
                    case 'st_body':
                    case 'st_box':
                    case 'st_body_bed':
                        furniture.korpus = value;
                        break;
                    case 'st_handles':
                        furniture.uchwyt = value;
                        break;
                    case 'st_accessories':
                        furniture.akcesoria = value;
                        break;
                }

                // SaveToCache(false, furniture);
                updateFurniture(furniture);
                updateDeliveryTime();

            }

            function changeViewContent(e) {

                // W tym kontekście wywołania this to powinien być obiekt: <div class="dekor st-miniature-container" data-name="">.

                var type = $(this).parent(".elementGroupMiniatures").data("type");
                var value = $(this).data("name");

                $(this).parent('.elementGroupMiniatures').find('.dekor .img').removeClass('st-position-in-step-selected');
                $(this).find('.img').addClass('st-position-in-step-selected');

                ChangeElement(type, value);
            }

            function changeFurnitureName(name) {

                $('.mainImage').attr('alt', name);
                $('#mainMenu .st-main-menu-header-name-bottom').text(name);
                $('.st-mobile-header .st-main-menu-header-name-bottom').text(name);
            }

            function changeFurnitureDimensions(dimensions) {
                function createDimensionsHtml() {
                    if (!dimensions) return '';
                    const keys = Object.keys(dimensions);
                    let html = '';

                    for (let i = 0; i < keys.length; i++) {
                        if (!dimensions[keys[i]]) return;
                        html += '<span class="st-main-menu-dimension-element">' + resource['trans_menu_dimensions_' + keys[i]] + ': ' + dimensions[keys[i]] + ' cm</span>';
                    }

                    return html;
                }

                $('.st-main-menu-dimensions').html(createDimensionsHtml());
            }


            function updateMenu() {
                if (furniture.korpus == null) {
                    $('#st-body-group-collapsed').parent().remove();
                }
                if (furniture.front == null) {
                    $('#st-front-group-collapsed').parent().remove();
                }
                if (furniture.nozki == null) {
                    $('#st-legs-group-collapsed').parent().remove();
                }
                if (furniture.uchwyt == null) {
                    $('#st-handles-group-collapsed').parent().remove();
                }
                if (furniture.akcesoria == null) {
                    $('#st_accessories-group-collapsed').parent().remove();
                }
                if (furniture.rama == null) {
                    $('#st-frame-group-collapsed').parent().remove();
                }
                $('#st-step-accordion .panel-default').each(function (i) {
                    if ($(this).find('.select-kolor').html() == '') {
                        $(this).remove();
                    }
                });
                $('#st-step-accordion .panel-default').each(function (i) {
                    var textStep = $(this).find('.st-step-accordion-header-label').html().split('.');
                    $(this).find('.st-step-accordion-header-label').html(parseInt(i + 1) + '.' + textStep[1]);
                });
                $('#st-step-accordion .panel-default').eq(0).find('.panel-heading').addClass('first');
            }

            function createPopover(element) {
                var html = 'data-toggle="popover" data-placement="top" data-trigger="hover" title="" ' +
                        'data-content="<img class=&quot;text-center st-popup-image&quot; src=&quot;' + element.img + '&quot; /><p class=&quot;st-preset-popover&quot;>' + element.desc + '</p>" ' +
                        'data-original-title="' + element.title + '"';
                return html;
            }

            var getAreaCalculator = function (screenWidth) {

                const width = parseInt(screenWidth);
                switch (width) {
                    case 853:
                        return furniture.largeAreaCalculator;
                    case 702:
                        return furniture.bigAreaCalculator;
                    case 614:
                        return furniture.smallAreaCalculator;
                    case 536:{
                        return  furniture.tinyAreaCalculator;}
                    default:
                        return furniture.mediumAreaCalculator;
                }
            }

            var getNewAccessoriesPosition = function (screenWidth, screenHeight) {

                var calculator = getAreaCalculator(screenWidth);
                return calculator.getNewAccessoriesPosition(furniture.wozki, screenWidth, screenHeight, furniture.code);
            }

            var getExsistingAccessoriesPosition = function (screenWidth, screenHeight, idx) {

                var calculator = getAreaCalculator(screenWidth);
                return calculator.getPositionFor(furniture.wozki, screenWidth, screenHeight, idx, furniture.code);
            }

            var getNewAccessoriesSize = function (screenWidth, screenHeight, size) {

                var calculator = getAreaCalculator(screenWidth);
                return calculator.getNewAccessoriesSize(screenWidth, screenHeight, size);
            }

            var refreshAccessories = function (element, zIndex) {

                var details = currentViewState.isDetailsView();

                var accessories = getAllAccessories();
                if (accessories.length && details) {
                    $('#main-image-container-accessories-remove').fadeIn('slow');
                    $('#main-image-container-delete-last-acc').fadeIn('slow');
                }

                var responsiveArray = [{
                    name: "xl1",
                    width: 960,
                    height: 570
                },
                    {
                        name: "xl2",
                        width: 734,
                        height: 570
                    },
                    {
                        name: "xl3",
                        width: 600,
                        height: 570
                    },
                    {
                        name: "l",
                        width: 734,
                        height: 570
                    },
                    {
                        name: "m",
                        width: 600,
                        height: 570
                    },
                    {
                        name: "s",
                        width: 367,
                        height: 285
                    },
                    {
                        name: "xs",
                        width: 300,
                        height: 233
                    }
                ];

                var respons = $('#mainImageContainer img:visible:not(.st-icon)').data('responsive');

                for (var i = 0; i < responsiveArray.length; ++i) {

                    var r = responsiveArray[i];
                    var name = r.name;

                    // Generowanie warstwy w zależności od rozmiarów kontenera.

                    var imageContainer = $('#mainImageContainer');

                    var width = $(imageContainer).width();
                    var height = $(imageContainer).height();

                    var newPos = getNewAccessoriesPosition(width, height);
                    var newSize = getNewAccessoriesSize(width, height, element.areaA);

                    // var newPos = 0;
                    // var newSize = 0;

                    var bottom = newPos.y;
                    var left = newPos.x;

                    var w = newSize.w;
                    var h = newSize.h;

                    if (!zIndex) {
                        zIndex = 98 - parseInt(accessories.length);
                    }

                    var style = 'style="position:absolute; bottom: ' + bottom + 'px; left:' + left + 'px; width: ' + w + 'px; height: ' + h + 'px; z-index:' + zIndex + ';"';
                    var imgHtml =
                            '<img class="st_accessories-img st-responsive-' + name + '"' +
                            ' src="' + element.url + '" ' + style +
                            ' data-accessories-id="' + element.id + '" data-accessories-lp="' + accessories.length + '" data-accessories-repons="' + name.toUpperCase() + '">';

                    $('#mainImageContainer').append(imgHtml);

                    if (details && respons === name.toUpperCase()) {
                        $('#mainImageContainer').find('.st_accessories-img:last').hide().fadeIn('fast', function () {
                            if (element['isUnavailable']) {
                                $(".st_accessories-img[src*='" + element.name + "']").each(function () {
                                    $(this).css("opacity", "0.6");
                                });
                            }
                        });
                    } else {
                        $('#mainImageContainer').find('.st_accessories-img:last').hide();
                    }
                }

            }

            /**
             * Aktualizacja ceny produktu.
             *  @param price - nowa cena produktu.
             */
            function updatePrice(furniture) { // 
                var price = 0.0;
                var new_price = 0.0;
                var currency = 'PLN';
                $.each(element.element, function (key, value) {
                    if(value !== null) {
                        $.each(value.option, function (k, option) {
                            // setPrice(key, option);
                            if (key == 'st_body' || key == 'st_box' || key == 'st_body_bed') {
                                if (option.material == furniture.korpus) {
                                    price += parseFloat(option.price);
                                    if (option.price_promo){
                                        new_price += parseFloat(option.price_promo)
                                    } else {
                                        new_price += parseFloat(option.price)
                                    }
                                    currency = option.currency;
                                }
                            }
                            if (key == 'st_front' || key == 'st_head' || key == 'st_front_bed') {
                                if (option.material == furniture.front) {
                                    price += parseFloat(option.price);
                                    if (option.price_promo){
                                        new_price += parseFloat(option.price_promo)
                                    } else {
                                        new_price += parseFloat(option.price)
                                    }
                                    currency = option.currency;
                                }
                            }
                            if (key == 'st_legs') {
                                if (option.material == furniture.nozki) {
                                    price += parseFloat(option.price);
                                    if (option.price_promo){
                                        new_price += parseFloat(option.price_promo)
                                    } else {
                                        new_price += parseFloat(option.price)
                                    }
                                    currency = option.currency;
                                }
                            }
                            if (key == 'st_handles') {
                                if (option.material == furniture.uchwyt) {
                                    price += parseFloat(option.price);
                                    if (option.price_promo){
                                        new_price += parseFloat(option.price_promo)
                                    } else {
                                        new_price += parseFloat(option.price)
                                    }
                                    currency = option.currency;
                                }
                            }
                            if (key == 'st_frame') {
                                if (option.material == furniture.rama) {
                                    price += parseFloat(option.price);
                                    if (option.price_promo){
                                        new_price += parseFloat(option.price_promo)
                                    } else {
                                        new_price += parseFloat(option.price)
                                    }
                                    currency = option.currency;
                                }
                            }
                            if (key == 'st_accessories_full') {
                                if (furniture.akcesoria_full.length > 0 && option.material == furniture.akcesoria_full[0]) {
                                    price += parseFloat(option.price);
                                    if (option.price_promo){
                                        new_price += parseFloat(option.price_promo)
                                    } else {
                                        new_price += parseFloat(option.price)
                                    }
                                    currency = option.currency;
                                }
                            }

                        });
                    }


                    if (key == 'st_accessories') {
                        for (var i = furniture.akcesoria.length - 1; i >= 0; i--) {
                            $.each(value.option, function (k, option) {          
                                if (option.material == furniture.akcesoria[i]) {
                                    price += parseFloat(option.price);
                                    if (option.price_promo){
                                        new_price += parseFloat(option.price_promo)
                                    } else {
                                        new_price += parseFloat(option.price)
                                    }
                                    currency = option.currency;
                                }
                            });
                        }
                    }
                });
                // var value = price === 'N/A' ? 'N/A' : Number(price).toFixed(0) + ' ' + options.currency;
                if(!currency) currency = options.currency;
                console.log('new price 2: ' + new_price);
                console.log('old price 2: ' + price);
                var value = price === 'N/A' ? 'N/A' : checkCurrency(currency, Number(price));
                var new_value = new_price === 'N/A' ? 'N/A' : checkCurrency(currency, Number(new_price));
                if (options.callbackInstallment && typeof options.callbackInstallment === 'function') {
                    options.callbackInstallment(price <= new_price ? value : new_value);
                }
                var html = false;
                if (new_price < price){
                    html = '<span class="st-main-menu-price-from">'+resource["trans_menu_price_from"]+'</span><span style="text-decoration: line-through">'+value+'</span></br><span class="st-main-menu-price-from">'+resource["trans_menu_price_from"]+'</span><span>'+new_value+'</span>'
                }
                // $('.st_main-menu-price-value').text(value);
                if (html){
                    $('.st-main-menu-price-value').html(html);
                } else {
                    $('.st-main-menu-price-value').text(value);
                }
            }

            async function updateDeliveryTime() {
                if (!options.showDeliveryTime) return;
                if (options.callbackAvailabilityAndDelivery && typeof options.callbackAvailabilityAndDelivery === 'function') {
                    const deliveryData = await options.callbackAvailabilityAndDelivery(getActualyConfig(furniture));
                    const deliveryElement = $('.st-delivery-container');
                    const mainButton = $('.additionalButtons.st-btn-red');
                    const setButtonInactive = function () {
                        const inactiveButtonContent = resource['trans_menu_unavailable'];
                        mainButton.attr('disabled', true);
                        mainButton.addClass('disabled');
                        mainButton.html(inactiveButtonContent);
                        deliveryElement.text(deliveryData.text);
                    };
                    const setButtonActive = function () {
                        const activeButtonContent = resource['trans_menu_add_to_card'] + '<img src="' + resource['img_basket'] + '" alt="koszyk" class="st-koszyk-white">';
                        mainButton.attr('disabled', false);
                        mainButton.removeClass('disabled');
                        mainButton.html(activeButtonContent);
                        deliveryElement.text(deliveryData.text);
                    };
                    if (deliveryData.status) {
                        setButtonActive();
                    } else {
                        setButtonInactive();
                    }
                    return;
                }

                const getSelectedElementsAvailability = function () {
                    const object = {count: [], availability_date: []};
                    const setAvailabilityObject = function (option) {
                        object.count.push(option.count ? parseInt(option.count) : 0);
                        object.availability_date.push(option.availability_date);
                    };

                    $.each(element.element, function (key, value) {
                        if (value !== null) {
                            $.each(value.option, function (_, option) {
                                if ((key === 'st_body' || key === 'st_box' || key === 'st_body_bed') && (option.material === furniture.korpus)) setAvailabilityObject(option);
                                if ((key === 'st_front' || key === 'st_head' || key === 'st_front_bed') && (option.material === furniture.front)) setAvailabilityObject(option);
                                if (key === 'st_legs' && option.material === furniture.nozki) setAvailabilityObject(option);
                                if (key === 'st_handles' && option.material === furniture.uchwyt) setAvailabilityObject(option);
                                if (key === 'st_frame' && option.material === furniture.rama) setAvailabilityObject(option);
                                if (key === 'st_accessories_full' && (furniture.akcesoria_full.length > 0 && option.material === furniture.akcesoria_full[0])) setAvailabilityObject(option);
                            });

                           if (key === 'st_accessories') {
                               for (var i = furniture.akcesoria.length - 1; i >= 0; i--) {
                                   $.each(value.option, function (k, option) {
                                       if (option.material === furniture.akcesoria[i]) setAvailabilityObject(option);
                                   });
                               }
                           }
                       }
                   });

                   return object;
               };

               const availabilityObject = getSelectedElementsAvailability();

                const allAvailable = availabilityObject.count.every(function (count, index) {
                    return count > 0 && !availabilityObject.availability_date[index];
                });

                const someNotAvailable = availabilityObject.count.some(function (count, index) {
                    return !count && !availabilityObject.availability_date[index];
                });

                const longDeliveryIndices = availabilityObject.count
                    .map(function (count, index) {
                        return count && availabilityObject.availability_date[index] ? index : null;
                    })
                    .filter(function (index) {
                        return index !== null;
                    });

               const availableWithLongDelivery = !someNotAvailable && longDeliveryIndices.length;

               const deliveryElement = $('.st-delivery-container');
               const mainButton = $('.additionalButtons.st-btn-red');
               let deliveryDays = 10;

                const setButtonActive = function () {
                    const deliveryText = `${resource['trans_menu_delivery']} ${deliveryDays} ${deliveryDays > 3 ? resource['trans_menu_working_day1'] : resource['trans_menu_working_day2']}`;
                    const activeButtonContent = resource['trans_menu_add_to_card'] + '<img src="' + resource['img_basket'] + '" alt="koszyk" class="st-koszyk-white">';
                    mainButton.attr('disabled', false);
                    mainButton.removeClass('disabled');
                    mainButton.html(activeButtonContent);
                    deliveryElement.text(deliveryText);
                };


                const setButtonInactive = function () {
                    const inactiveButtonContent = resource['trans_menu_unavailable'];
                    mainButton.attr('disabled', true);
                    mainButton.addClass('disabled');
                    mainButton.html(inactiveButtonContent);
                    deliveryElement.text('');
                };

                const calculateLongDelivery = function () {
                    const dateCheck = function (dateToCheck) {
                        return dateToCheck instanceof Date && !isNaN(dateToCheck);
                    };
                    const unixDates = availabilityObject.availability_date
                        .map(function (dateString) {
                            if (!dateString) return 0;
                            const date = new Date(dateString);
                            date.setDate(date.getDate());
                            return dateCheck(date) ? date.getTime() : 0;
                        })
                        .filter(function (unix, index) {
                            return unix > 0 && longDeliveryIndices.includes(index);
                        });

                   const mostDistantDate = Math.max.apply(null, unixDates);
                   const now = (new Date()).getTime();

                   const getWorkingDays = function (unixFrom, unixTo) {
                       let dateFrom = new Date(unixFrom);
                       let dateTo = new Date(unixTo);
                       let numOfWorkingDays = 1;

                       if (!dateCheck(dateFrom) || !dateCheck(dateTo)) return;

                       while (dateFrom < dateTo) {
                           dateFrom.setDate(dateFrom.getDate() + 1);
                           const day = dateFrom.getDay();
                           if (day !== 0 && day !== 6) numOfWorkingDays++;
                       }

                       return numOfWorkingDays;
                   };

                   const workingDaysUntilProduction = getWorkingDays(now, mostDistantDate);
                   const buffer = 10;

                   deliveryDays = mostDistantDate > now ? workingDaysUntilProduction + buffer : buffer;
               };

               if (allAvailable) {
                   setButtonActive();
                   return;
               }

               if (someNotAvailable) {
                   setButtonInactive();
                   return;
               }

               if (availableWithLongDelivery) {
                   calculateLongDelivery();
                   setButtonActive();
               }
           }

           function addAccessories(furnitureId, id, set=false) {

                function getElement(id, furnitureId, translator) {

                    var accessoriesManager = new Accessories_Class(furnitureId, translator);
                    var element = accessoriesManager.getAccessoriesInfo(id);
                    // element = {
                    //   name : id,
                    //   id: id,
                    //   realWidth: 15,
                    //   areaA:{"a":282.71999999999997,"b":156.24},
                    //   offset : 0,
                    // };
                    // element.realWidth = 15;


                    return element;
                }

                function haveEnoughPlace(element, accessories, accessoriesArea, busWidth) {

                    var accessoriesWidth = 0;

                    for (var i = 0; i < accessories.length; ++i) {

                        var a = accessories[i];
                        accessoriesWidth += a.realWidth;
                    }

                    accessoriesWidth += element.realWidth;

                    return accessoriesWidth <= busWidth;
                }

                var elementToAdd = getElement(id, furnitureId, '');

                if (haveEnoughPlace(elementToAdd, furniture.wozki, furniture.bigAreaCalculator.getAccessoriesArea(), furniture.busWidth)) {
                    elementToAdd['set'] = set;
                    furniture.wozki.push(elementToAdd);
                    return elementToAdd;
                }

                return null;
            }

            function initAccessories(menu, option) {

                if (option.material == '') {
                    return false;
                }

                var appendTo = $("#st_accessories-group-collapsed [data-type='st_accessories']");
                var appendFullTo = $("#st_accessories-group-collapsed [data-type='st_accessories_full']");

                var description = '';

                if (typeof option.code_tmp !== 'undefined') {
                    option.code = option.code_tmp;
                    // description = resource["trans_" + option.code];
                }

                description = resource["trans_" + option.material];
                if (typeof description === 'undefined') {
                    description = resource["trans_" + option.code];
                }
                if (typeof description === 'undefined') {
                    description = "!_trans_" + option.code;
                }
                var p = {
                    img: url,
                    desc: description,
                    title: description
                }

                var accOne = false;
                var accId = null;
                if (option.code == 'zestaw-zaslepek-do-komody-simple') {
                    accId = '["akc_wyp15","akc_wyp15","akc_wyp15","akc_wyp40"]';
                } else if (option.code == 'zestaw-akcesoriow-do-komody-simple') {
                    accId = '["akc_telefon","akc_probowka","akc_tablet","akc_probowka","akc_probowka","akc_probowka","akc_ramka","akc_probowka","akc_wyp40"]';
                } else if (option.code == 'ramka-do-zdjec-simple') {
                    accId = '["akc_ramka"]';
                    accOne = true;
                } else if (option.code == 'uchwyt-do-tabletu-simple') {
                    accId = '["akc_tablet"]';
                    accOne = true;
                } else if (option.code == 'uchwyt-do-telefonu-simple') {
                    accId = '["akc_telefon"]';
                    accOne = true;
                } else if (option.code == 'ramka-do-zdjec-simple') {
                    accId = '["akc_telefon"]';
                    accOne = true;
                } else if (option.code == 'wazonik-simple') {
                    accId = '["akc_probowka"]';
                    accOne = true;
                } else if (option.code == 'zaslepka-150-simple') {
                    accId = '["akc_wyp15"]';
                    accOne = true;
                } else if (option.code == 'zaslepka-250-simple') {
                    accId = '["akc_wyp25"]';
                    accOne = true;
                } else if (option.code == 'zaslepka-400-simple') {
                    accId = '["akc_wyp40"]';
                    accOne = true;
                } else if (option.code == 'zestaw-akcesoriow-do-biurka-simple') {
                    accId = '["akc_probowka","akc_probowka","akc_probowka","akc_probowka","akc_tablet","akc_ramka","akc_tablet","akc_telefon","akc_wyp40"]';
                } else if (option.code == 'zestaw-zaslepek-do-biurka-simple') {
                    accId = '["akc_wyp40","akc_wyp15","akc_wyp40","akc_wyp40"]';
                } else if (option.code == 'zestaw-akcesoriow-do-stolika-nocnego-simple') {
                    accId = '["akc_telefon","akc_probowka","akc_probowka","akc_probowka","akc_ramka"]';
                }
                // else if( option.material == 'zestaw-zaslepek-do-stolika-nocnego-simple' )
                // {
                //   accId = '["akc_telefon"]';
                // }
                // else if( option.material == 'zestaw-akcesoriow-do-szafki-rtv-180-simple' )
                // {
                //   accId = '["akc_telefon"]';
                // }
                else if (option.code == 'zestaw-zaslepek-do-szafki-rtv-180-simple') {
                    accId = '["akc_wyp40","akc_wyp40","akc_wyp40","akc_wyp40"]';
                }
                // if( accId == null ){
                //     return;
                // }
                var _popover = createPopover(p, "top");
                var url = resource["img_icon_" + option.code];

                var html =
                        '<div class="dekor st-miniature-container" data-name="' + option.code + '" ' + _popover + ' data-acc-id=' + accId + ' data-acc=' + accOne + '>' +
                        '<div class="dip-pw-color-sample img" style="background-image:' + "url('" + url + "')" + ';"></div>' +
                        // '<div class="name">' + description + '</div>' +
                        '</div>';
                var append = null;
                if (menu == 'st_accessories') {
                    // if( furniture.akcesoria.length == 0 )
                    // {
                    //   furniture.akcesoria.push(option.material);
                    // }
                    appendTo.append(html);
                    append = appendTo;
                }
                if (menu == 'st_accessories_full') {
                    // if( furniture.akcesoria_full.length == 0 )
                    // {
                    //   furniture.akcesoria_full.push(option.material);
                    // }
                    appendFullTo.append(html);
                    append = appendFullTo;
                }

                if( append != null ){
                    var miniatures = append.find('.dekor');
                
                    for (var i = 0; i < miniatures.length; ++i) {

                        var m = miniatures[i];
                        if ($(m).data('name') == option.material) {
                            $(m).on('click', {acc: $(m).data('acc')}, addAccessoriesViewContent);
                            break;
                        }
                    }
                }


                // appendFullTo.find('.dekor').popover({
                //     html: true
                // });


                // appendTo.find('.dekor').on('click', function(e) {
                //     menuInstance.addAccessoriesToScene(e, this, false, menuInstance);
                // });
                // appendTo.find('.dekor').popover({
                //     html: true
                // });

            }


            function addAccessoriesViewContent(e) {
                e.preventDefault();
                var accessoriesIdsArray = $(this).data('acc-id');
                var accessoriesName = $(this).data('name');
                var runOutPlace = false;

                // Usunięcie wszystkich dotychczas wstawionych akcesoriów.
                if (e.data.acc != true) {
                    removeAllAccesories();
                    updateDeliveryTime();
                    $('#mainImageContainer .st_accessories-img').remove();
                }

                // Dodanie gotowego zestawu.

                // var isUnavailable = menuInstance.isUnavailableAccesories(accessoriesName);
                
                for (var i = 0; i < accessoriesIdsArray.length; ++i) {

                    // if (currentViewState.isMainView()) {
                    //     menuInstance.switchToDetailsViewA();
                    // }

                    var accessoriesId = accessoriesIdsArray[i];


                    var addedElement = addAccessories(furniture.code, accessoriesId, !e.data.acc);
                    // var addedElement = accessoriesId;
                    if (addedElement != null) {
                        // var name = addedElement;
                        // addedElement = { url : '../assets/img/accessories/' + furniture.code + '_' + name.name + '.png', id: 1, areaA:name.areaA};
                        // if(isUnavailable) addedElement['isUnavailable'] = true;
                        refreshAccessories(addedElement);

                        if (e.data.acc == true) {
                            furniture.akcesoria.push(accessoriesName);
                        } else {
                            furniture.akcesoria_full.push(accessoriesName);
                        }
                    } else {
                        runOutPlace = true;
                    }
                }

                // Zapamiętanie w obiekcie mebla informacji o ustawionym zestawie.

                // var presetAsOne = $(this).data('as-one');
                // var presetName = $(this).data('name');

                // menuInstance.furniture.SetPresetFlag(presetAsOne ? presetName : '');

                // Aktualizacja ceny.

                // var furniturePrice = menuInstance.simpleCreator.GetFurniturePriceBasedOnCurrentState();
                updatePrice(furniture);
                updateDeliveryTime();

                // if (runOutPlace) {
                //     menuInstance.showNoPlaceAlert();
                // } else {

                //     $('.st-preset-description-container').slideUp('slow');
                //     menuInstance.hideNoPlaceAlert();
                // }

                // Pokazanie odpowiedniej zaślepki.

                var respons = $('#mainImageContainer img:visible:not(.st-icon)').data('responsive');
                var hoverImgs = $('.mainImageHover');
                for (var i = 0; i < hoverImgs.length; ++i) {

                    var h = hoverImgs[i];
                    var resp = $(h).data('responsive');

                    if (resp === respons) {
                        $(h).show();
                    }
                }

                // menu.simpleCreator.SaveToCache(false);
            };

            function initMenu(menu, option) {

                if (option.material == '') {
                    return false;
                }

                if ( menu == 'st_no_head') {
                    if( furniture.countHeadOrNotHead == 0 )
                    {
                        option.material = 'off_head';
                        menu = 'st_head';
                    }
                    furniture.countHeadOrNotHead++;
                }

                var appendTo = $("#" + menu + "-group-collapsed .elementGroupMiniatures");

                // var description = this.simpleTalkResources.getResource(element.desc);
                var description = '';
                description = resource["trans_" + option.material];
                if (typeof description === 'undefined') {
                    description = resource["trans_" + option.code];
                }
                if( typeof option.code_tmp !== 'undefined' && menu === 'st_frame' ){
                    description = resource["trans_" + option.code_tmp];
                }
                if ((menu === 'st_legs' || menu === 'st_frame' || menu === 'st_head') && (typeof description !== 'undefined' && ( description.split(' ').length === 1 || description.length < 9))) {
                    description += '<br/>&nbsp;';
                }

                var _popover = "";
                var _frame = "";
                // if(menu == "st-frame" && element.name == "frame-metal")
                // {
                //     var popObj = {
                //         title: this.simpleTalkResources.getResource("cart-frame-bed") + " " + this.simpleTalkResources.getResource("cart-color-name-frame-metal"),
                //         img: "simple_bed_frame.png",
                //         desc: ""
                //     }
                //     _popover = CreatePopover(popObj);
                //     _frame = 'frame';
                // }

                if (menu == 'st_front' && furniture.front == null) {
                    furniture.front = option.material
                }
                if (menu == 'st_body_bed' && furniture.korpus == null) {
                    furniture.korpus = option.material
                }
                if (menu == 'st_front_bed' && furniture.front == null) {
                    furniture.front = option.material
                }
                if (menu == 'st_legs' && furniture.nozki == null) {
                    furniture.nozki = option.material
                }
                if (menu == 'st_body' && furniture.korpus == null) {
                    furniture.korpus = option.material
                }
                if (menu == 'st_handles' && furniture.uchwyt == null) {
                    furniture.uchwyt = option.material
                }
                if (menu == 'st_box' && furniture.korpus == null) {
                    furniture.korpus = option.material;
                    furniture.isBed = true;
                }
                if (menu == 'st_head' && furniture.front == null) {
                    furniture.front = option.material;
                    furniture.isBed = true;
                }
                if (menu == 'st_frame' && furniture.rama == null) {
                    furniture.rama = option.material;
                    furniture.isBed = true;
                }

                var price = option.price_promo != null ? option.price_promo : option.price;

                if( typeof option.code_tmp !== 'undefined' && menu === 'st_frame' ){
                    var url = resource["img_icon_frame-bed"];
                }
                else{
                    var url = resource["img_icon_" + option.material];
                }
                var html =
                        '<div class="dekor st-miniature-container ' + _frame + '" data-name="' + option.material + '" ' + _popover + ' data-price=' + price + '>' +
                        '<div class="dip-pw-color-sample img" style="background-image:' + "url('" + url + "')" + ';"></div>' +
                        '<div class="name">' + description + '</div>' +
                        '</div>';


                appendTo.append(html);

                var miniatures = appendTo.find('.dekor');
                for (var i = 0; i < miniatures.length; ++i) {

                    var m = miniatures[i];
                    if ($(m).data('name') == option.material) {

                        $(m).on('click', changeViewContent);
                        break;
                    }
                }
            }

            $.each(element.element, function (key, value) {
                if (value !== null) {
                    if (key != 'st_accessories_full' && key != 'st_accessories') {
                        $.each(value.option, function (k, option) {
                            initMenu(key, option);
                        });
                    } else {
                        $.each(value.option, function (k, option) {
                            initAccessories(key, option);
                        });
                    }
                }
            });

            function getBaseActualyConfig(furniture) {
                var resultsElements = [];
                var resultsElementsIndex = -1;
                $.each(element.element, function (key, value) {
                    var material = {material_id: null, count: 0, price: 0};
                    if (value !== null) {
                    $.each(value.option, function (k, option) {
                        // setPrice(key, option);
                        if (key == 'st_body' || key == 'st_box' || key == 'st_body_bed') {
                            if (option.material == furniture.korpus) {
                                material = {
                                    material_name: option.name,
                                    material_id: option.material_id,
                                    count: 1,
                                    price: parseFloat(option.price),
                                    type: key
                                };
                                if (options.alternativeBasket) {
                                    material = {
                                        material_name: option.name,
                                        name: value.name.replace(' ', '-'),
                                        card_id: option.card_id,
                                        product_id: option.card_id,
                                        count: 1,
                                        availability: parseInt(option.count),
                                        title: value.name + ': ' + resource['trans_' + option.material],
                                        type: key
                                    };
                                }
                            }
                        }
                        if (key == 'st_front' || key == 'st_head' || key == 'st_front_bed') {
                            if (option.material == furniture.front) {
                                material = {
                                    material_name: option.name,
                                    material_id: option.material_id,
                                    count: 1,
                                    price: parseFloat(option.price),
                                    type: key
                                };
                                if (options.alternativeBasket) {
                                    material = {
                                        material_name: option.name,
                                        name: value.name.replace(' ', '-'),
                                        card_id: option.card_id,
                                        product_id: option.card_id,
                                        count: 1,
                                        availability: parseInt(option.count),
                                        title: value.name + ': ' + resource['trans_' + option.material],
                                        type: key
                                    };
                                }
                            }
                        }
                        if (key == 'st_no_head') {
                            if (furniture.front == 'off_head' && ( option.material == furniture.korpus || ( option.material == 'off_head' && furniture.korpus == 'white' ) ) ) {

                                var titleBuffor = value.name + ': ' + resource['trans_' + option.material];
                                if( option.material == 'off_head' && furniture.korpus == 'white' ){
                                    titleBuffor = value.name + ': ' + resource['trans_white'];
                                }
                                // resultsElementsFind = ;
                                resultsElementsIndex = resultsElements.indexOf(resultsElements.find(resultsElements => resultsElements.type === "st_box"));

                                material = {
                                    material_name: option.name,
                                    material_id: option.material_id,
                                    count: 1,
                                    price: parseFloat(option.price),
                                    type: key
                                };
                                if (options.alternativeBasket) {
                                    material = {
                                        material_name: option.name,
                                        name: value.name.replace(' ', '-'),
                                        card_id: option.card_id,
                                        product_id: option.card_id,
                                        count: 1,
                                        availability: parseInt(option.count),
                                        title: titleBuffor,
                                        type: key
                                    };
                                }

                                if( resultsElementsIndex != -1 ){
                                    resultsElements[resultsElementsIndex] = material;
                                    material = null;
                                }
                            }
                        }
                        if (key == 'st_legs') {
                            if (option.material == furniture.nozki) {
                                material = {
                                    material_name: option.name,
                                    material_id: option.material_id,
                                    count: 1,
                                    price: parseFloat(option.price),
                                    type: key
                                };
                                if (options.alternativeBasket) {
                                    var materialText = option.material;
                                    if (option.material == 'legs-none') {
                                        materialText = 'legs-slipper';
                                    }
                                    material = {
                                        material_name: option.name,
                                        name: value.name.replace(' ', '-'),
                                        card_id: option.card_id,
                                        product_id: option.card_id,
                                        count: 1,
                                        availability: parseInt(option.count),
                                        title: value.name + ': ' + resource['trans_' + materialText],
                                        type: key
                                    };
                                }
                            }
                        }
                        if (key == 'st_handles') {
                            if (option.material == furniture.uchwyt) {
                                material = {
                                    material_name: option.name,
                                    material_id: option.material_id,
                                    count: 1,
                                    price: parseFloat(option.price),
                                    type: key
                                };
                                if (options.alternativeBasket) {
                                    material = {
                                        material_name: option.name,
                                        name: value.name.replace(' ', '-'),
                                        card_id: option.card_id,
                                        product_id: option.card_id,
                                        count: 1,
                                        availability: parseInt(option.count),
                                        title: value.name + ': ' + resource['trans_' + option.material],
                                        type: key
                                    };
                                }
                            }
                        }
                        if (key == 'st_frame') {
                            if (option.material == furniture.rama) {
                                material = {
                                    material_name: option.name,
                                    material_id: option.material_id,
                                    count: 1,
                                    price: parseFloat(option.price),
                                    type: key
                                };
                                if (options.alternativeBasket) {
                                    material = {
                                        material_name: option.name,
                                        name: value.name.replace(' ', '-'),
                                        card_id: option.card_id,
                                        product_id: option.card_id,
                                        count: 1,
                                        availability: parseInt(option.count),
                                        title: value.name,
                                        type: key
                                    };
                                }
                            }
                        }
                        if (key == 'st_accessories_full') {
                            if (furniture.akcesoria_full.length > 0 && option.material == furniture.akcesoria_full[0]) {
                                material = {
                                    material_name: option.name,
                                    material_id: option.material_id,
                                    count: 1,
                                    price: parseFloat(option.price),
                                    type: key
                                };
                                if (options.alternativeBasket) {
                                    material = {
                                        material_name: option.name,
                                        name: value.name.replace(' ', '-'),
                                        card_id: option.card_id,
                                        product_id: option.card_id,
                                        count: 1,
                                        availability: parseInt(option.count),
                                        title: value.name + ': ' + resource['trans_' + option.material],
                                        type: key
                                    };
                                }
                            }
                        }

                    });
                }

                    var accesoriesMaterial = [];

                    if (key == 'st_accessories') {
                        for (var i = furniture.akcesoria.length - 1; i >= 0; i--) {
                            $.each(value.option, function (k, option) {
                                if (option.material == furniture.akcesoria[i]) {
                                    material = {
                                        material_name: option.name,
                                        material_id: option.material_id,
                                        count: 1,
                                        price: parseFloat(option.price),
                                        type: key
                                    };
                                    if (options.alternativeBasket) {
                                        material = {
                                            material_name: option.name,
                                            name: value.name.replace(' ', '-'),
                                            card_id: option.card_id,
                                            product_id: option.card_id,
                                            count: 1,
                                            availability: parseInt(option.count),
                                            title: value.name + ': ' + option.name,
                                            type: key
                                        };
                                    }
                                    accesoriesMaterial.push(material);
                                }
                            });
                        }
                    }
                    var keyRemove = [];
                    for (var i = 0; i < accesoriesMaterial.length; i++) {
                        var count = 0;
                        if (jQuery.inArray(i, keyRemove) == -1) {
                            for (var k = 0; k < accesoriesMaterial.length; k++) {
                                if (typeof accesoriesMaterial[k] !== 'undefined' && accesoriesMaterial[i].material_name == accesoriesMaterial[k].material_name) {
                                    if (count != 0) {
                                        keyRemove.push(k);
                                    }
                                    count++;
                                }
                            }
                            accesoriesMaterial[i].count = count;
                        }
                    }

                    for (var i = accesoriesMaterial.length - 1; i >= 0; i--) {
                        if (jQuery.inArray(i, keyRemove) == -1) {
                            resultsElements.push(accesoriesMaterial[i]);
                        }
                    }

                    if ((material != null && material.material_name != null && accesoriesMaterial.length == 0)) {
                        resultsElements.push(material);
                    }

                });

                var resultsObj = {
                    product_id: element.prod,
                    product_name: element.name,
                    elements: resultsElements,
                    price: furniture.main_price || ''
                }

                if (options.alternativeBasket) {
                    try {
                        resultsObj.thumb = currentViewState.getState().thumbUrl;
                    } catch (e) {
                        resultsObj.thumb = '';
                    }
                }

                return resultsObj;
            }


            function checkAvalibility(basket) {
                var unavailability = [];

                $.each(basket.elements, function (k, type) {
                    if (type.availability < type.count) {
                        unavailability.push(type.title);
                    }
                });

                if (unavailability.length == 0) {
                    return null
                }
                return unavailability;
            }


            /**
             * Aktualizacja konfiguracja koszyka
             */
            function getActualyConfig(furniture) {

                // if(config.alternativeBasket)
                // {
                //     return getAlternativeActualyConfig(furniture);
                // }
                return getBaseActualyConfig(furniture);

            }


            function setDimensions() {
                if (options.showDimensions && typeof element.dimensions !== undefined) {
                    furniture.dimensions = element.dimensions;
                }
            }


            $('#st-configurator-modal span.st-close').on('click', function (e) {

                e.preventDefault();
                $('#st-configurator-modal').css('display', 'none');
            });


            furniture.code = element.code;
            furniture.name = element.name;
            furniture.head = element.comparehead;
            furniture.id = element.id;

            setDimensions();

            var thisBigDisplayInfo = null;
            var thisLargeDisplayInfo = null;
            var thisMediumDisplayInfo = null;
            var thisSmallDisplayInfo = null;
            var thisTinyDisplayInfo = null;

            if (furniture.code == 'ST_komoda_90_listwa') {
                thisLargeDisplayInfo = {
                    "A": {"a": 960, "b": 570},
                    "B": {"a": 597, "b": 320},
                    "S": {"x": 90, "y": 146}
                };
                thisBigDisplayInfo = {
                    "A": {"a": 960, "b": 570},
                    "B": {"a": 597, "b": 325},
                    "S": {"x": 102, "y": 217}
                };
                thisMediumDisplayInfo = {
                    "A": {"a": 475, "b": 570},
                    "B": {"a": 456, "b": 247},
                    "S": {"x": 60, "y": 104}
                };
                thisSmallDisplayInfo = {
                    "A": {"a": 600, "b": 570},
                    "B": {"a": 372, "b": 202},
                    "S": {"x": 72, "y": 179}
                };
                thisTinyDisplayInfo = {
                    "A": {"a": 475, "b": 570},
                    "B": {"a": 456, "b": 247},
                    "S": {"x": 33, "y": 160}
                };
                furniture.busWidth = 85;
            }
            if (furniture.code == 'ST_biurko_140') {
                thisLargeDisplayInfo = {
                    "A": {"a": 960, "b": 570},
                    "B": {"a": 757, "b": 408},
                    "S": {"x": 55, "y": 141}
                };
                thisBigDisplayInfo = {
                    "A": {"a": 960, "b": 570},
                    "B": {"a": 757, "b": 412},
                    "S": {"x": 55, "y": 204}
                };
                thisMediumDisplayInfo = {
                    "A": {"a": 450, "b": 570},
                    "B": {"a": 579, "b": 312},
                    "S": {"x": 30, "y": 91}
                    // "A": {"a": 734, "b": 570},
                    // "B": {"a": 579, "b": 312},
                    // "S": {"x": 42, "y": 142}
                };
                thisSmallDisplayInfo = {
                    "A": {"a": 600, "b": 570},
                    "B": {"a": 479, "b": 258},
                    "S": {"x": 37, "y": 170}
                };
                thisTinyDisplayInfo = {
                    "A": {"a": 734, "b": 570},
                    "B": {"a": 579, "b": 312},
                    "S": {"x": 36, "y": 250}
                };

                furniture.busWidth = 135;
            }
            if (furniture.code == 'ST_stolik_nocny_szuflady' || furniture.code == "ST_stolik_nocny_szuflady_kw") {
                thisLargeDisplayInfo = {
                    "A": {"a": 960, "b": 570},
                    "B": {"a": 390, "b": 210},
                    "S": {"x": 148, "y": 231}
                };
                thisBigDisplayInfo = {
                    "A": {"a": 960, "b": 570},
                    "B": {"a": 390, "b": 210},
                    "S": {"x": 174, "y": 309}
                };
                thisMediumDisplayInfo = {
                    "A": {"a": 520, "b": 570},
                    "B": {"a": 297, "b": 159},
                    "S": {"x": 98, "y": 159}
                };
                thisSmallDisplayInfo = {
                    "A": {"a": 600, "b": 570},
                    "B": {"a": 242, "b": 130},
                    "S": {"x": 84, "y": 222}
                };
                thisTinyDisplayInfo = {
                    "A": {"a": 520, "b": 570},
                    "B": {"a": 297, "b": 159},
                    "S": {"x": 75, "y": 226}
                };
                furniture.busWidth = 40;
            }
            if (furniture.code == 'ST_rtv_180' || furniture.code == 'ST_rtv_180_kw') {
                thisLargeDisplayInfo = {
                    "A": {"a": 960, "b": 570},
                    "B": {"a": 750, "b": 415},
                    "S": {"x": 63, "y": 92}
                };
                thisBigDisplayInfo = {
                    "A": {"a": 960, "b": 570},
                    "B": {"a": 750, "b": 415},
                    "S": {"x": 52, "y": 156}
                };
                thisMediumDisplayInfo = {
                    "A": {"a": 420, "b": 100},
                    "B": {"a": 570, "b": 318},
                    "S": {"x": 37, "y": 69}

                    // "A": {"a": 734, "b": 570},
                    // "B": {"a": 570, "b": 318},
                    // "S": {"x": 48, "y": 110}
                };
                thisSmallDisplayInfo = {
                    "A": {"a": 600, "b": 570},
                    "B": {"a": 467, "b": 256},
                    "S": {"x": 40, "y": 138}
                };
                thisTinyDisplayInfo = {
                    "A": {"a": 420, "b": 100},
                    "B": {"a": 570, "b": 318},
                    "S": {"x": 6, "y": 113}
                };
                furniture.busWidth = 175;
            }

            if (thisLargeDisplayInfo != null && thisMediumDisplayInfo != null && thisSmallDisplayInfo != null) {
                furniture.largeAreaCalculator = new AccessoriesArea_Class(thisLargeDisplayInfo.A, thisLargeDisplayInfo.B, thisLargeDisplayInfo.S);
                furniture.bigAreaCalculator = new AccessoriesArea_Class(thisBigDisplayInfo.A, thisBigDisplayInfo.B, thisBigDisplayInfo.S);
                furniture.mediumAreaCalculator = new AccessoriesArea_Class(thisMediumDisplayInfo.A, thisMediumDisplayInfo.B, thisMediumDisplayInfo.S);
                furniture.smallAreaCalculator = new AccessoriesArea_Class(thisSmallDisplayInfo.A, thisSmallDisplayInfo.B, thisSmallDisplayInfo.S);
                furniture.tinyAreaCalculator = new AccessoriesArea_Class(thisTinyDisplayInfo.A, thisTinyDisplayInfo.B, thisTinyDisplayInfo.S);
            }

            setLowerPricesConfiguration();
            updateFurniture(furniture);
            updateMenu();
            changeFurnitureName(furniture.name);
            changeFurnitureDimensions(furniture.dimensions);
            updateDeliveryTime();

            function setLowerPricesConfiguration() {
               $('.st-main-menu-sections').find('.elementGroupMiniatures').each(function(index, value) { 
                   var pricePromo = 0;
                   var beforeSelect = $(value).find('.dekor.st-miniature-container').eq(0);
                   $(value).find('.dekor.st-miniature-container').each(function(inx, el) { 
                       if( $(el).data('price') != 0 ){
                           if( pricePromo == 0 || parseFloat(pricePromo) > parseFloat($(el).data('price')) ){
                               beforeSelect.removeClass('click-select-start-price');
                               pricePromo = $(el).data('price');
                               $(el).addClass('click-select-start-price');
                               beforeSelect = $(el);
                           }
                       }
                   })
               });
               $('.st-main-menu-sections').find('.elementGroupMiniatures').each(function(index, value) {
                   if ($(value).data('type') == 'st_front' && $(value).find('.click-select-start-price').length != 0) {
                       furniture.front = $(value).find('.click-select-start-price').data('name');
                   }
                   if ($(value).data('type') == 'st_body_bed'  && $(value).find('.click-select-start-price').length != 0) {
                       furniture.korpus = $(value).find('.click-select-start-price').data('name');
                   }
                   if ($(value).data('type') == 'st_front_bed'  && $(value).find('.click-select-start-price').length != 0) {
                       furniture.front = $(value).find('.click-select-start-price').data('name');
                   }
                   if ($(value).data('type') == 'st_legs'  && $(value).find('.click-select-start-price').length != 0) {
                       furniture.nozki = $(value).find('.click-select-start-price').data('name');
                   }
                   if ($(value).data('type') == 'st_body'  && $(value).find('.click-select-start-price').length != 0) {
                       furniture.korpus = $(value).find('.click-select-start-price').data('name');
                   }
                   if ($(value).data('type') == 'st_handles'  && $(value).find('.click-select-start-price').length != 0) {
                       furniture.uchwyt = $(value).find('.click-select-start-price').data('name');
                   }
                   if ($(value).data('type') == 'st_box'  && $(value).find('.click-select-start-price').length != 0) {
                       furniture.korpus = $(value).find('.click-select-start-price').data('name');
                   }
                   if ($(value).data('type') == 'st_head' && $(value).find('.click-select-start-price').length != 0) {
                       furniture.front = $(value).find('.click-select-start-price').data('name');
                   }
                   if ($(value).data('type') == 'st_frame' && $(value).find('.click-select-start-price').length != 0) {
                       furniture.rama = $(value).find('.click-select-start-price').data('name');
                   }
                   $(value).find('.click-select-start-price .img').addClass('st-position-in-step-selected');
                   $(value).find('.click-select-start-price').removeClass('click-select-start-price');
               });
            }


           function disableMagnifier() {  

                if (magnifierOn) {
                    $('#main-image-container-magnifier button').css({'background-color': '#FFF', 'color': '#000'});

                    $('.magnifier-lens').remove();
                    $('.magnifier-large').remove();
                    $('.mainImage').removeAttr('id');

                    magnifierOn = false;

                    // $.ajax({
                    //     url: '../server/mergeImages.php?deleteImage=true',
                    //     type: 'post',
                    //     data: {'data': imgToDelete},
                    //     datatype: 'json',
                    //     success: function(data) {

                    //         console.log("Obraz usunięty z serwera");
                    //     },
                    //     error: function(data){

                    //         console.log('Błąd przy usuwaniu obrazu z serwera');
                    //     }
                    //     });
                }
            }

            var magnificationHoverOpenFullImage = function (e) {
                e.preventDefault();
                var event = e;
                var $magnificationImageContent = $('.magnification-content');
                var $magnificationImage = $('.magnification-image');
                var $mainImageWrapper = $('.mainImage.st-responsive-xl1');
                var photo = prepareDataForMerge();
                var size = 'auto';

                var widthOriginalImage = $magnificationImageContent.width();
                var heightOriginalImage = $magnificationImageContent.height();

                $magnificationImage.attr("src", photo).css('width', size);

                // $magnificationImage.load(function(){
                $mainImageWrapper.fadeOut(300, function () {

                    $magnificationImageContent.removeClass('magnification-content--hidden');
                    var widthMagnificationImage = $magnificationImage.width();
                    var heightMagnificationImage = $magnificationImage.height();

                    var scaleWidthImage = (widthMagnificationImage - widthOriginalImage) / widthOriginalImage;
                    var scaleheightImage = (heightMagnificationImage - heightOriginalImage) / heightOriginalImage;
                    magnificationMoveStart($magnificationImageContent, event.pageX, event.pageY, scaleWidthImage, scaleheightImage);
                    $magnificationImageContent.mousemove({
                                mainImageWrapper: $mainImageWrapper,
                                scaleWidthImage: scaleWidthImage,
                                scaleheightImage: scaleheightImage,
                                magnificationImageContent: $magnificationImageContent
                                // magnificationImageContent: $('.vpw-gallery__magnification')
                            },
                            magnificationMove);

                    $magnificationImageContent.mouseleave(magnificationRestart);
                })
                // });


            }
            var magnificationRestart = function (e) {
                var $magnificationImageContent = $('.magnification-content');

                var $mainImageWrapper = $('.mainImage.st-responsive-xl1');

                $magnificationImageContent.addClass('magnification-content--hidden');


                $magnificationImageContent.off('mousemove');
                $magnificationImageContent.off('mouseleave');

                $mainImageWrapper.show();
            }
            var magnificationMoveStart = function ($magnificationImageContent, posX, posY, scaleWidthImage, scaleheightImage) {


                var offset = $magnificationImageContent.offset();

                var moveX = (posX - offset.left) * scaleWidthImage;
                var moveY = (posY - offset.top) * scaleheightImage;

                $magnificationImageContent.find('img').css('transform', 'translate3d(-' + moveX + 'px,-' + moveY + 'px,0px)');
            }
            var magnificationMove = function (e) {
                var $magnificationImageContent = e.data.magnificationImageContent;
                var scaleWidthImage = e.data.scaleWidthImage;
                var scaleheightImage = e.data.scaleheightImage;

                var offset = $magnificationImageContent.offset();

                var moveX = (e.clientX - offset.left) * scaleWidthImage;
                var moveY = (e.clientY - offset.top) * scaleheightImage;

                $magnificationImageContent.find('img').css('transform', 'translate3d(-' + moveX + 'px,-' + moveY + 'px,0px)');
            }

            function prepareDataForMerge() {
                var thisState = currentViewState.getState();

                return thisState.magnifierUrl;
            }

            var saveActualConfiguration = function () {
                $.ajax({
                    url: options.saveConfigurationUrl,
                    type: 'post',
                    data: {'data': prepareDataForMerge()},
                    datatype: 'json',
                    beforeSend: function () {
                        console.log("przygotowywanie obrazu...");
                        $('.col-preview').prepend('<img class="loadingMagnifier" src="../assets/img/loading.gif">');
                        $('#mainImageContainer').css({'background-color': 'grey', 'opacity': '0.5'});
                    },
                    success: function (path) {

                        // Korekta ściezki do duzego obrazka widocznego jak najedziemy kursorem na scene
                        // path = path.replace('"', '').replace('"', '').replace(' ', '');

                        var a = document.createElement('a');
                        a.href = "../" + path;
                        a.download = "mebel_SimpleTalk.png";
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);

                        setTimeout(
                                function () {
                                    console.log("obraz przygotowany: " + path);
                                    $('.col-preview .loadingMagnifier').remove();
                                    $('#mainImageContainer').css({'background-color': 'transparent', 'opacity': '1'});
                                }, 500);

                    },
                    error: function (path) {
                        Console.log('Błąd przy tworzeniu obrazu do pobrania');
                    }
                });
            }

            $('#main-image-container-magnifier').click(magnificationHoverOpenFullImage);
            $('#main-image-container-save-actual-configuration').click(saveActualConfiguration);


            $('#main-image-container-accessories-remove, #main-image-container-delete-last-acc').on('click', function (e) {

                e.preventDefault();

                // I nie wykonuj mi żadnego kliknięcia na elementy leżące pod spodem.

                e.stopPropagation();

                // disableMagnifier();

                if (haveToRemoveAllAccessories()) {

                    removeAllAccesories();
                    updateDeliveryTime();
                    $('#mainImageContainer .st_accessories-img').remove();

                    // furniture.SetPresetFlag('');
                    $(this).hide();
                } else {

                    var removedElement = removeLastAccessories();
                    if (removedElement == null) {
                        $(this).hide();
                    } else {

                        var accessoriesImg = $('#mainImageContainer .st_accessories-img');

                        if(removedElement.set){
                            for (var i = 0; i < accessoriesImg.length; ++i) {
                                var a = accessoriesImg[i];
                                $(a).fadeOut('fast', function () {
                                    $(this).remove();
                                });
                            }
                            furniture.akcesoria_full = [];
                        }else{
                            for (var i = 0; i < accessoriesImg.length; ++i) {

                                var a = accessoriesImg[i];

                                var id = $(a).data('accessories-id');
                                var lp = $(a).data('accessories-lp');

                                var lpToRemove = getAllAccessories().length + 1;

                                if (removedElement.id === parseInt(id) && lpToRemove === parseInt(lp)) {
                                    $(a).fadeOut('fast', function () {
                                        $(this).remove();
                                    })
                                }
                            }
                            furniture.akcesoria.pop();
                        }

                        if (!getAllAccessories().length) {
                            $(this).hide();
                        }
                    }
                }

                // Usunięcie ewentulnego komunikatu o przeciążeniu mebla akcesoriami.

                var show = $('#st_accessories-group-collapsed .alert').length === 0;
                if (!show) {
                    $('#st_accessories-group-collapsed').find('.alert:last').slideUp('fast', function () {
                        $(this).remove();
                    });
                }

                // SaveToCache(false);

                // var furniturePrice = GetFurniturePriceBasedOnCurrentState();
                updatePrice(furniture);
            });

            $('.st-step-accordion-header-collapse').on('click', function (e) {
                var ICON_SELECTOR = '.st-step-accordion-header-collapse-icon';

                function changeCollapseIconUp(collapse) {

                    var icon = $(collapse).find(ICON_SELECTOR);
                    icon.addClass('collapse-icon-up');
                }

                function changeCollapseIconDown(collapse) {

                    var icon = $(collapse).find(ICON_SELECTOR);
                    icon.removeClass('collapse-icon-up');
                }

                function changeAllCollapseIcon() {

                    var links = $('.st-step-accordion-header-collapse');
                    if (links.length) {

                        for (var i = 0; i < links.length; ++i) {

                            var l = links.get(i);
                            changeCollapseIconDown($(l));
                        }
                    }
                }

                // Użycie bug hacka'a.

                var currentElement = $(this).attr('href');
                $(currentElement).off('shown.bs.collapse', forceChangeTheOthersVisibility).on('shown.bs.collapse', forceChangeTheOthersVisibility);
                $(currentElement).off('show.bs.collapse', forceChangeTheOthersVisibility).on('show.bs.collapse', forceChangeTheOthersVisibility);
                
                // Zmiana ikony we wszystkich pozycjach.
                // $(collapsedId).show();
                changeAllCollapseIcon();

                // Aktualizacja klikniętej pozycji.

                var collapsed = $(this).hasClass('collapsed');

                if (collapsed) {
                    changeCollapseIconUp($(this));
                } else {
                    changeCollapseIconDown($(this));
                }

               
                if ($(this).parents('.panel-heading').attr('id') === 'st_accessories-group') {

                    if (collapsed && !currentViewState.isDetailsView()) {

                        $('#main-image-container-view-change-front').removeClass('active');
                        $('#main-image-container-view-change-details').addClass('active');

                        ChangeViewToDetails();
                    }
                } else {

                    if (collapsed && !currentViewState.isMainView()) {

                        $('#main-image-container-view-change-front').addClass('active');
                        $('#main-image-container-view-change-details').removeClass('active');

                        ChangeViewToMain();
                    }
                }

            });

            $("#zatwierdz").on('click', function (e) {
                options.callbackSaveFurniture(getActualyConfig(furniture));
            });
           $("#seeInShowroom").on('click', function (e) {
               options.callbackSeeInShowroom();
           });

           $("#askQuestion").on('click', function (e) {
               options.callbackAskQuestion();
           });


           return false;
        }


        function hideAllLoadingControls() {

            $('#st-special-loading-canvas').hide();
            $('.mainImageHover ').hide();

            $('#fog').fadeOut('slow');
            if (!options.fromVoxBox) {
                $('.st-configurator-close-container').hide();
            }
            if (options.allowBackToList) {
                $('#backToList').show();
            }
            $('.st-furniture-select-container').show();
        }

        var element = null;

        function sortElementsByPrice(data) {
            Object.keys(data.element).forEach(function (key) {
                if (data.element[key]) {
                    const option = data.element[key].option;
                    let parsedOption;

                    if (Array.isArray(option)) {
                        parsedOption = option.sort(function (a, b) {
                            return parseInt(a.price) - parseInt(b.price);
                        });
                    } else {
                        const obj = {};
                        const sortedOption = Object.keys(option).sort(function (a, b) {
                            return parseInt(option[a].price) - parseInt(option[b].price);
                        });
                        sortedOption.forEach(function(k) {
                            obj[k] = option[k];
                        });
                        parsedOption = obj;
                    }
                    data.element[key].option = parsedOption;
                }
            });

            return data;
        }

        function create() {
            console.log(options.furnitureSelectUrl);
            if (options.furnitureSelectUrl) {
                var postUrl = options.furnitureSelectUrl + options.productId;
                console.log(postUrl);
                if (options.lang != null) {
                    if( options.apiSelect == 'pimcore' )
                    {
                        postUrl = postUrl + '/lang/' + options.lang + '?' + $.param(simpleConfig.region);
                    }
                    else{
                        postUrl = postUrl + '?lang=' + options.lang;
                    }
                }
                $.ajaxSetup({
                    headers:getHeaders()
                });
                $.post(postUrl)
                
                        .done(function (data) {
                            // if( options.productId == 18835 )
                            // {
                            //   AccessoriesArea_Class()
                            // }

                            element = sortElementsByPrice(data);

                            createMenu();
                            // price.initPrice();

                        })
                        .fail(function (data) {
                            console.log("error getting carousel furniture data");
                        });
            } else {
                furnituresList = "";
                // getInitTranslateData(options.translateService, afterInitTranslateDataLoaded);
            }

        }

        function setParameters(param) {
            options.showLoadingOnStart = param.showLoadingOnStart;
            options.showCloseButtonInHeader = param.showCloseButtonInHeader;
            options.collectionUrl = param.collectionUrl;
            options.showViewSwitch = param.showViewSwitch;

            options.allowBackToList = param.allowBackToList;
            options.fromVoxBox = param.fromVoxBox;
            options.includeLink = param.includeLink;
            options.domain = param.domain;
            options.furnitureSelectUrl = param.furnitureSelectUrl;
            options.apiConfigurationUrl = param.apiConfigurationUrl;
            options.apiGetImageUrl = param.apiGetImageUrl;
            options.currency = param.currency;
            options.region = param.region;

            if (param.hasOwnProperty('showDimensions')) {
                options.showDimensions = param.showDimensions;
            }
            if (param.hasOwnProperty('alternativeBasket')) {
                options.alternativeBasket = param.alternativeBasket;
            }
            if (param.hasOwnProperty('apiSelect')) {
                options.apiSelect = param.apiSelect;
            }
            if (param.hasOwnProperty('saveConfigurationUrl') && param.hasOwnProperty('saveBtn')) {
                options.saveConfigurationUrl = param.saveConfigurationUrl;
                options.saveBtn = param.saveBtn;
            }
            if (param.hasOwnProperty('linkToGallery')) {
                options.linkToGallery = param.linkToGallery;
            }
            if (param.hasOwnProperty('magnifierBtn')) {
                options.magnifierBtn = param.magnifierBtn;
            }
            if (param.hasOwnProperty('callbackInstallment')) {
                options.callbackInstallment = param.callbackInstallment;
            }
            if (param.hasOwnProperty('showDeliveryTime')) {
                options.showDeliveryTime = param.showDeliveryTime;
            }
            if (param.hasOwnProperty('showOmnibus')) {
                options.showOmnibus = param.showOmnibus
            }
            if (param.hasOwnProperty('shopEnable') && options.shopEnable == null) {
                options.shopEnable = param.shopEnable
            }
            if (param.hasOwnProperty('hasPrice') && options.hasPrice == null) {
                options.hasPrice = param.hasPrice
            }
        }

        function init(e) {
            
            if (options.apiConfigurationUrl) {
                options.domain = window.location.origin;
                var json = JSON.stringify(options);
                $.ajaxSetup({
                    headers:getHeaders()
                });
                $.post(options.apiConfigurationUrl, {
                    data: json
                }).done(function (data) {
                   
                    data = JSON.parse(JSON.stringify(data));
                    if (typeof data.options !== 'undefined') {
                        var parameters = JSON.parse(data.options);
                        resource = data.resource;
                        setParameters(parameters);

                        e.html(simpleTalkCreator());

                        


                        if (data.hasOwnProperty('shop')) {
                            if (!data.shop) {
                                $('.st-main-menu-footer').remove();
                            }
                        }
                        if (data.hasOwnProperty('lang')) {
                            options.lang = data.lang
                        }

                        if (data.hasOwnProperty('showOmnibus')) {
                            options.showOmnibus = data.showOmnibus
                        }
                        
                    }

                    if(options.callbackSaveFurniture === null){
                        $('.st-main-menu-footer').remove();
                    }

                    create();
                    hideAllLoadingControls();
                })
                        .fail(function () {
                            console.log("error getting carousel furniture data");
                        });
            } else {
                furnituresList = "";

            }

        }


        init($(this));


    }
}(jQuery));
